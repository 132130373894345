import { Box } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { ProgressBarHyperLink } from '../../features/counter/counterSlice';
// import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';
import { fetchContentData, fetchSectionData } from '../../utils/SecondLayoutApiCall';
import SecondeLayout from '../SecondLayout/SecondeLayout';

const MuiTextTypo = ({ apidata, content }) => {
  const [open, setOpen] = React.useState(false);
  const [hyprerlinkPageID, setHyprerlinkPageID] = React.useState({});
  // const dispatch = useDispatch();
  // const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);
  const chart_data = useMemo(() => {
    if (content) {
      const labels_id = content?.label?.[0]?.lbl_id;
      const api_data = apidata?.labels;
      if (labels_id !== 'l_blank') {
        let filterd_data = api_data?.filter((items) => items?.lbl_id === labels_id);
        return filterd_data?.[0]?.lbl_descr;
      }
    }
  }, [apidata]);

  const TextColoring = {
    color: content?.label?.[0]?.lbl_id === 'lbl_3' ? '#FFA500' : '#000000',
    cursor: content?.label?.[0]?.hpr_pg_id !== null ? 'pointer' : 'default'
  };

  // redux part for hyperlink
  const handleClickOpen = async () => {
    if (content?.label?.[0]?.hpr_pg_id) {
      const contentdata = await fetchContentData(content?.label?.[0]?.hpr_pg_id);
      const sectiondata = await fetchSectionData(content?.label?.[0]?.hpr_pg_id);
      setHyprerlinkPageID({
        HyperLinkPageId: content?.label?.[0]?.hpr_pg_id,
        DynamicObjectForBar: {},
        fetch_Content_Data: contentdata,
        fetch_Section_Data: sectiondata
      });
      setOpen(true);
      // below condition is for empty redux state
      //   if (hyperdata.length) {
      //     RemoveProgressBarHyperlinkObject(hyperdata);
      //   }
      //   dispatch(ProgressBarHyperLink({ ['hyperPage_id']: content?.label?.[0]?.hpr_pg_id }));
      //   dispatch(ProgressBarHyperLink({ ['DefaultBrowserPageId']: layout?.pg_id }));
    }
  };

  // const RemoveProgressBarHyperlinkObject = (hyperdata) => {
  //   dispatch(RemoveProgressBarHyperLink(hyperdata));
  //   dispatch(RemoveProgressBarHyperLink());
  // };
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography
          gutterBottom
          sx={{ ...TextColoring }}
          onClick={() => handleClickOpen(chart_data)}
          fontWeight="bold"
        >
          {chart_data}
        </Typography>
      </Box>
      {open && <SecondeLayout open={open} setOpen={setOpen} Page_id_input={hyprerlinkPageID} />}
    </>
  );
};

export default MuiTextTypo;
MuiTextTypo.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
