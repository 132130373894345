import React, { useState, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const consolidateDuplicateRowsByName = (data) => {
  const seenNames = new Set();
  const consolidatedData = [];

  data.forEach((row) => {
    if (!seenNames.has(row.name)) {
      seenNames.add(row.name);
      consolidatedData.push(row);
    }
  });

  return consolidatedData;
};
const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9)
];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const ExampleTable = () => {
  const allColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name'
      },
      {
        accessorKey: 'age',
        header: 'Age'
      },
      {
        accessorKey: 'email',
        header: 'Email'
      },
      {
        accessorKey: 'address',
        header: 'Address'
      },
      {
        accessorKey: 'city',
        header: 'City'
      }
    ],
    []
  );

  const [visibleColumns, setVisibleColumns] = useState(allColumns);

  const data = useMemo(
    () => [
      {
        name: 'John Doe',
        age: 30,
        email: 'john.doe@example.com',
        address: 'kolkata',
        city: 'uk'
      },
      {
        name: 'John Doe',
        age: 25,
        email: 'jane.smith@example.com',
        address: 'kolkata',
        city: 'uk'
      },
      {
        name: 'John Doe',
        age: 35,
        email: 'mike.johnson@example.com',
        address: 'kolkata',
        city: 'uk'
      }
    ],
    []
  );

  console.log(consolidateDuplicateRowsByName(data));
  const handleColumnToggle = (event) => {
    const {
      target: { value }
    } = event;
    setVisibleColumns(allColumns.filter((column) => value.includes(column.header)));
  };

  const tableInstance = useMaterialReactTable({
    columns: visibleColumns,
    data
  });

  return (
    <Box>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Columns</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={visibleColumns
            .filter((item) => item.accessorKey !== 'name')
            .map((col) => col.header)}
          onChange={handleColumnToggle}
          input={<OutlinedInput label="Columns" />}
          renderValue={(selected) => selected.join(', ')}
        >
          {allColumns
            .filter((item) => item.accessorKey !== 'name')
            .map((column) => (
              <MenuItem key={column.accessorKey} value={column.header}>
                <Checkbox
                  checked={visibleColumns.some((col) => col.accessorKey === column.accessorKey)}
                />
                <ListItemText primary={column.header} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <MaterialReactTable table={tableInstance} />
      {/* basic table below */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ border: 'none' }}>Dessert (100g serving)</TableCell>
              <TableCell sx={{ border: 'none' }} align="right">
                Calories
              </TableCell>
              <TableCell sx={{ border: 'none' }} align="right">
                Fat&nbsp;(g)
              </TableCell>
              <TableCell sx={{ border: 'none' }} align="right">
                Carbs&nbsp;(g)
              </TableCell>
              <TableCell sx={{ border: 'none' }} align="right">
                Protein&nbsp;(g)
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row" sx={{ border: 'none' }}>
                  {row.name}
                </TableCell>
                <TableCell sx={{ border: 'none' }} align="right">
                  {row.calories}
                </TableCell>
                <TableCell sx={{ border: 'none' }} align="right">
                  {row.fat}
                </TableCell>
                <TableCell sx={{ border: 'none' }} align="right">
                  {row.carbs}
                </TableCell>
                <TableCell sx={{ border: 'none' }} align="right">
                  {row.protein}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ExampleTable;
