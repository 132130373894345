import { TextField } from '@mui/material';
import { object } from 'prop-types';

const Field = ({ field, ...rest }) => {
  return (
    <TextField
      type={field?.dty}
      label={field?.fld_l}
      sx={{ width: 'fit-content' }}
      inputProps={{ maxLength: field?.lngth }}
      helperText={rest?.value?.length ? `${rest?.value?.length} of ${field?.lngth}` : null}
      {...rest}
    />
  );
};

Field.propTypes = {
  field: object.isRequired
};

export default Field;
