import { createTheme } from '@mui/material';

export const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none !important'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 0
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '36px'
        }
      }
    }
  }
});
