import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';

// mui imports
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
// import MenuList from '@mui/material/MenuList';
// import MenuItem from '@mui/material/MenuItem';
import { Grid, Typography } from '@mui/material';
// import Section from '../Section/Section';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemText from "@mui/material/ListItemText";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// import Http from '../../utils/http';
// import { toast } from 'react-toastify';

const Menu = ({
  items,
  present,
  setPresent,
  dynamic_page,
  setDynamic_page,
  summaryData,
  setSummeryData
  // pageId
}) => {
  const [active, setActive] = useState(null);
  // const [dynamic_page, setDynamic_page] = useState(null);
  // const [present, setPresent] = useState(false); /*to show table*/
  const [open, setOpen] = useState(false); /*for dropdown*/
  // const [summaryData, setSummeryData] = useState(null);

  const handleClick = (value) => {
    if (value.children && value.children.length > 0) {
      setOpen((isopen) => !isopen);
      setPresent(false);
    } else {
      if (value?.pageSection?.[0]?.children.length === 0) {
        setPresent(true);
      } else {
        setPresent(false);
      }
    }
  };

  const menuItemClickHandler = (current) => {
    setActive(current);
  };

  // const fetchSectionData = useCallback(
  //   async (pg_id, sctn_id) => {
  //     try {
  //       const response = await Http.post('/get-page-content-data-wrt-ids', { pg_id, sctn_id });
  //       if (!response.data?.error) {
  //         if (response.data?.data.length >= 2) {
  //           setSummeryData(response.data?.data);
  //           setPresent(false);
  //         } else {
  //           setSummeryData(null);
  //           setOpen(false);
  //         }
  //       } else {
  //         toast.error(response.data?.error);
  //       }
  //     } catch (e) {
  //       toast.error(e?.message);
  //     }
  //   },
  //   [summaryData]
  // );

  React.useEffect(() => {
    setSummeryData(null);
    setPresent(false);
  }, [items]);

  return Array.isArray(items) ? (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        {
          <Paper sx={{ width: 320 }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {items?.map((child, i) => [
                i !== 0 && <Divider />,
                <ListItemButton
                  key={child?.sctn_id}
                  onClick={() => {
                    menuItemClickHandler(child?.pg_id);
                    // if child does not have children , print child.
                    if (child?.pageSection?.[0]?.children.length === 0) {
                      setDynamic_page(child);
                    } else {
                      // if child has children and divided in 'T', print its childrens.
                      if (
                        child?.pageSection?.[0]?.children?.length &&
                        child?.pageSection?.[0]?.dvd_in === 'T'
                      ) {
                        setSummeryData(child?.pageSection);
                      }
                    }
                    handleClick(child);
                    // if child has childrens and divided in "R" including "T" in childrens will print all. planning output-1 summery
                    if (
                      child?.pageSection?.[0]?.children?.length &&
                      child?.pageSection?.[0]?.dvd_in === 'R'
                    ) {
                      setSummeryData(child?.pageSection?.[0]?.children);
                    }
                  }}
                  selected={active === child?.pg_id}
                >
                  <Typography
                    color={active === child?.pg_id ? 'Black' : 'gray'}
                    key={child?.sctn_id}
                  >
                    {child?.mnu_tt || child?.mnu_id}
                  </Typography>

                  {child.children &&
                    child.children.length > 0 &&
                    (open ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>,

                child?.children && child?.children?.length ? (
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Menu
                      items={child?.children}
                      present={present}
                      setPresent={setPresent}
                      dynamic_page={dynamic_page}
                      setDynamic_page={setDynamic_page}
                      summaryData={summaryData}
                      setSummeryData={setSummeryData}
                      key={i}
                    />
                  </Collapse>
                ) : null
              ])}
            </List>
          </Paper>
        }
      </Grid>
      {/* 
      {present ? (
        <Grid item xs={9}>
          <Section data={{ lnkd_pg_id: dynamic_page?.pg_id, cntnt: dynamic_page?.sctn_id }} />
        </Grid>
      ) : null} */}

      {/* {!present && summaryData?.length ? (
        <Grid item xs={9}>
          {summaryData?.map((data) => (
            <Section
              data={{
                lnkd_pg_id: data?.pageContent?.[0]?.pg_id,
                cntnt: data?.pageContent?.[0]?.sctn_id
              }}
              key={`${data?.pageContent?.[0]?.pg_id}-${data?.pageContent?.[0]?.sctn_id}`}
            />
          ))}
        </Grid>
      ) : null} */}
    </Grid>
  ) : null;
};

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  present: PropTypes.bool,
  setPresent: PropTypes.func,
  dynamic_page: PropTypes.any,
  setDynamic_page: PropTypes.func,
  summaryData: PropTypes.any,
  setSummeryData: PropTypes.func
  // pageId:PropTypes.any,
};
export default Menu;
