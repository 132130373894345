import { Stack, CircularProgress, Typography } from '@mui/material';

const Loader = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100%"
      spacing={4}
      direction="row"
      py={8}
    >
      <CircularProgress />
      <Typography variant="h6">Loading, please wait..</Typography>
    </Stack>
  );
};

export default Loader;
