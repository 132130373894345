// top level imports
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import { IoArrowBackCircle } from 'react-icons/io5';

// mui
import { Typography, Breadcrumbs, Link, Stack, Box } from '@mui/material';

// MUI icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// Context-API
import { BreadcrumbContext } from '../../context';

const BreadCrumbsNav = () => {
  // fetching data from context
  const { breadCrumbs } = useContext(BreadcrumbContext);

  const handleClick = () => {
    window.location.href = '/';
  };

  return (
    <Box mt={-1} mb={1}>
      <Stack alignItems="center" direction="row" spacing={2}>
        {/* menu title */}
        {/* <Box display="flex" marginLeft="7%">
          <Typography variant="h5">{pageTitle || ''}</Typography>
        </Box> */}
        <Box display="flex" marginLeft="2%">
          <Typography variant="h5" sx={{ cursor: 'pointer', fontSize: '2rem' }}>
            {' '}
            <IoArrowBackCircle onClick={handleClick} />
          </Typography>
        </Box>

        {/* Breadcrumbs */}
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
          sx={{ fontSize: '12px' }}
        >
          {breadCrumbs?.map((breadCrumbsName, index) => {
            return index + 1 === breadCrumbs?.length ? (
              // <Typography variant="caption" key={breadCrumbsName?.mnu_id}>
              //   {breadCrumbsName?.mnu_nm}
              // </Typography>
              <></>
            ) : (
              <Link
                component={ReactRouterLink}
                preventScrollReset={true}
                underline="hover"
                color="inherit"
                href="/"
                key={breadCrumbsName?.mnu_id}
              >
                {breadCrumbsName?.mnu_nm}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Stack>
    </Box>
  );
};

BreadCrumbsNav.propTypes = {
  pageTitle: PropTypes.node
};

export default BreadCrumbsNav;
