import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const [landingMenu, SetLandingMenu] = useState([]);
  const navigate = useNavigate();
  const EmployeID = localStorage.getItem('emp_id');

  useEffect(() => {
    if (!EmployeID) {
      window.location.href = '/login';
    }
    if (localStorage.getItem('MenuData')) {
      let menuDataString = localStorage.getItem('MenuData');
      let menuData = JSON.parse(menuDataString);
      SetLandingMenu(menuData);
    }
  }, []);
  // console.log('landingMenu...', landingMenu);

  const handleclick = (item) => {
    // console.log('itemQQQ', `app/${item?.a_id}/${item?.slug}`);
    navigate(`app/${item?.a_id}/${item?.slug}`);
  };

  return (
    <>
      {EmployeID && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              backgroundColor: 'white',
              paddingBottom: '30rem',
              marginLeft: '20%',
              marginRight: '20%'
            }}
          >
            <Grid container spacing={2} sx={{ marginTop: '80px', padding: '20px' }}>
              {landingMenu?.map((item) => (
                <>
                  <Grid item xs={12} md={3} sm={4} key={item?.pg_id}>
                    <Card
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        maxWidth: 170,
                        minHeight: '170px',
                        borderRadius: '30px',
                        cursor: 'pointer',
                        justifyContent: 'center'
                      }}
                      onClick={() => handleclick(item)}
                    >
                      <CardContent>
                        <Typography variant="h6" color="blue" fontWeight="bold" textAlign="center">
                          {item?.mnu_nm}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default LandingPage;
