import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, LabelList } from 'recharts';
import PropTypes from 'prop-types';
import DoubleHorizontalBar from './DoubleHorizontalBar';
import Grid from '@mui/material/Grid';

const HorizontalBar = ({ chrt_id, apidata, item }) => {
  // console.log('item@...', item, chrt_id);
  const chart_data = useMemo(() => {
    if (chrt_id) {
      let filterd_data = apidata?.chart?.filter((items) => items?.[chrt_id]);
      return filterd_data?.[0]?.[chrt_id];
    }
  }, [apidata]);

  let q = [];
  for (const i of chart_data?.chartTableData || []) {
    let obj = {};
    for (const j of item?.children?.[0]?.pageContent?.[0]?.chartDetail || []) {
      if (j.axis === 'x') {
        obj[j.fld_id] = i[j.fld_id];
      } else {
        obj[j?.ttl] = i[j.fld_id];
      }
    }
    q.push(obj);
  }

  const DomainKey = chart_data?.chartDetail?.[0]?.['fld_id'];
  const maxActDocCt = q.reduce((max, current) => {
    const currentVal = parseInt(current[DomainKey]);
    return isNaN(currentVal) ? max : Math.max(max, currentVal);
  }, 0);

  const CustomYAxisTick = ({ x, y, payload }) => (
    <text
      x={x - 100}
      y={y}
      textAnchor="center"
      dy={3}
      fontSize="14px"
      fill="#000000"
      fontWeight="500"
    >
      {payload.value}
    </text>
  );
  CustomYAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
    chrt_id: PropTypes.string,
    item: PropTypes.object,
    itemData: PropTypes.object
  };

  const CustomLabel = ({ x, y, width, value }) => {
    const isLargeBar = width > 100; // Adjust this threshold as needed
    const labelX = isLargeBar ? x + width - 30 : x + width + 5; // Adjust label position

    return (
      <text
        x={labelX}
        y={y + 10} // Adjust y position to vertically center the label
        fill="#00000"
        fontSize="0.75rem"
        textAnchor="start" // Adjust text alignment
      >
        {value !== 0 ? value : null}
      </text>
    );
  };

  CustomLabel.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    value: PropTypes.number
  };

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography fontSize="16px" fontWeight="bold" textAlign="center">
              {item?.children?.[0]?.lbl || 'No label'}
            </Typography>
            <Typography color="transparent" fontSize="16px">
              testing
            </Typography>
            <Box sx={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '400px' }}>
              <Box sx={{ width: '800px' }}> </Box>
              <ResponsiveContainer
                height={
                  item?.children?.[1]?.pageContent?.[0]?.chrt_id === 'flm_c_a2_m2_s4.t2_t2_c1'
                    ? 600
                    : 400
                }
                width="100%"
              >
                <BarChart
                  data={q || []}
                  layout="vertical"
                  margin={{ left: 50, right: 50, top: 20, bottom: 10 }}
                  width={800}
                  height={600}
                >
                  <XAxis
                    hide
                    type="number"
                    domain={[0, 'dataMax + ' + maxActDocCt]}
                    padding={{ left: 70, right: 20 }}
                  />
                  <YAxis
                    type="category"
                    dataKey={chart_data?.chartDetail?.[1]?.['ttl'] || 'no name'}
                    tickLine={false}
                    axisLine={false}
                    tick={<CustomYAxisTick />}
                    interval={0}
                    // padding={{ top: 5, bottom: 5 }}
                  />
                  <Bar
                    dataKey={chart_data?.chartDetail?.[0]?.['fld_id'] || 'no name'}
                    fill="#6082B6"
                    minPointSize={0}
                    barSize={13}
                    barGap={1}
                    barCategoryGap={5}
                    stroke="#000000"
                    strokeWidth={1}
                  >
                    <LabelList
                      dataKey={chart_data?.chartDetail?.[0]?.['fld_id']}
                      position="right"
                      fill="#00000"
                      fontSize={'15px'}
                      fontWeight={'500'}
                      formatter={(value) => (value !== 0 ? value : null)}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <span
              style={{
                position: 'relative',
                bottom: '3px',
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '16px'
              }}
            >
              {item?.children?.[1]?.lbl}
            </span>

            <Box sx={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '425px' }}>
              <Box sx={{ width: '800px' }}> </Box>
              <Grid container spacing={1}>
                {item?.children?.[1]?.pageContent?.map((itemData) => (
                  <>
                    <Grid item xs={4} key={itemData?.chrt_id}>
                      <Typography textAlign="end" fontSize="14px" fontWeight="600">
                        {itemData?.chartDetail?.[0]?.ttl}
                      </Typography>

                      <DoubleHorizontalBar
                        apidata={apidata}
                        item={item}
                        itemData={itemData}
                        chrt_id={itemData?.chrt_id}
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HorizontalBar;
HorizontalBar.propTypes = {
  childLayout: PropTypes.array,
  layout: PropTypes.object,
  apidata: PropTypes.object,
  chrt_id: PropTypes.string,
  item: PropTypes.object
};
