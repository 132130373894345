import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Http from '../../utils/http';
import LayoutItem from '../../layouts/DynamicLayout/LayoutItem';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { IoChevronBackCircleOutline } from 'react-icons/io5';
import Container from '@mui/material/Container';
import { useSelector } from 'react-redux';

const rootStyle = {
  transform: 'scale(0.8)', // Scale down to 80%
  transformOrigin: 'top left', // Ensure scaling from the top-left corner
  width: '125%', // Adjust width to compensate for scaling
  height: '125%' // Adjust height to compensate for scaling
};

function getCurrentMonthName() {
  const monthNames = {
    0: '01',
    1: '02',
    2: '03',
    3: '04',
    4: '05',
    5: '06',
    6: '07',
    7: '08',
    8: '09',
    9: '10',
    10: '11',
    11: '12'
  };
  const currentDate = new Date();
  return [monthNames[currentDate.getMonth()]];
}

function SimpleDialog(props) {
  const { open, layout, setOpen, apidata } = props;

  return (
    <Dialog open={open} fullScreen sx={rootStyle}>
      <Container maxWidth="false" sx={{ backgroundColor: 'aliceblue' }}>
        {layout.map((item, index) => (
          <React.Fragment key={index}>
            <Accordion defaultExpanded={true} disableGutters expanded={true}>
              <AccordionSummary
                aria-controls={layout.sctn_id}
                id={layout.sctn_id}
                style={{ minHeight: '10px' }}
              >
                {index === 0 ? (
                  <span>
                    <IoChevronBackCircleOutline
                      style={{ margin: 0, fontSize: '25px' }}
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </span>
                ) : (
                  ''
                )}
                <span style={{ fontWeight: 'bold', fontSize: '20px' }}>{layout?.lbl || ''}</span>
              </AccordionSummary>
              <AccordionDetails>
                <LayoutItem layout={item} apidata={apidata} />
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        ))}
      </Container>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  layout: PropTypes.array,
  apidata: PropTypes.object
};

export default function SecondeLayout({ open, setOpen, Page_id_input }) {
  const [layout, setLayout] = React.useState([]);
  const [apidata, setApiData] = useState();
  //*******************Data api calling here*******************************/

  // const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);
  const dropDownValues = useSelector((state) => state.counter.getDropDownValue);
  const dropDownValuesYear = useSelector((state) => state.counter.year);
  const dropDownValuesmonth = useSelector((state) => state.counter.month);
  const dropDownValuesMrName = useSelector((state) => state.counter.mrName);
  const dropDownValuesamount = useSelector((state) => state.counter.amount);
  const dropDownValuemonthSingle = useSelector((state) => state.counter.monthSingle);
  const dropDownValuesrange = useSelector((state) => state.counter.range);
  const dropDownValuesTopBottomPerformers = useSelector(
    (state) => state.counter.TopBottomPerformers
  );

  const Page_a2_ApiCall = async (Page_id_input) => {
    const emp_id = localStorage.getItem('emp_id');
    const role_id = localStorage.getItem('role_id');
    const currentYear = new Date().getFullYear();

    try {
      const response = await Http.post('/fetch-all-page-data', {
        a_id: 'a2',
        pg_id: Page_id_input.HyperLinkPageId,
        emp_id: `${emp_id}`,
        week_number: dropDownValues.toString(),
        field_values: {},
        action: {},
        sel_table: {},
        // input_values: hyperdata?.length ? hyperdata?.[2]?.BarInfo : {},
        input_values: Page_id_input?.DynamicObjectForBar,
        year: dropDownValuesYear.length ? dropDownValuesYear : [`${currentYear}`],
        month: dropDownValuesmonth.length ? dropDownValuesmonth : getCurrentMonthName(),
        MRName: dropDownValuesMrName?.length ? dropDownValuesMrName : [],
        amount: dropDownValuesamount[0]?.length ? dropDownValuesamount.toString() : 'Lacs',
        month_single: dropDownValuemonthSingle?.length
          ? dropDownValuemonthSingle
          : getCurrentMonthName(),
        top_bottom_performers: dropDownValuesTopBottomPerformers.toString(),
        range: parseInt(dropDownValuesrange),
        role_id: role_id
      });
      if (!response.data?.error) {
        return response?.data?.data;
      }
    } catch (e) {
      console.log(e?.message);
    }
  };

  //*******************Data api calling ends here*******************************/

  useEffect(() => {
    const All_Data = Page_a2_ApiCall(Page_id_input);
    All_Data.then((res) => setApiData(res));
    setLayout(pageSectionStructureGenerator(Page_id_input?.fetch_Section_Data));
  }, [Page_id_input.DynamicObjectForBar]);

  const contentGenerator = useCallback(
    (sctn_id) => {
      const pageContentObj = Page_id_input?.fetch_Content_Data;
      let returnValue = [];
      if (pageContentObj) {
        returnValue = [
          ...pageContentObj
            .filter((contentObj) => contentObj?.pageData?.[0]?.sctn_id === sctn_id)
            .map((item) => item?.pageContent?.flat())
            .flat()
        ];
      }

      return sctn_id ? returnValue : [];
    },
    [Page_id_input]
  );
  const pageSectionStructureGenerator = useCallback(
    (pageSections) => {
      const pg_sctns = pageSections?.filter(
        (item) => item?.pg_id === Page_id_input.HyperLinkPageId
      );

      const sectionsMap = {};
      const result = [];

      // Create a map of menu items using their mnu_id
      for (const item of pg_sctns) {
        const { sctn_id } = item;
        sectionsMap[sctn_id] = { ...item, children: [], pageContent: [] };
      }
      // Iterate over the menu items again to build the hierarchy
      for (const item of pg_sctns) {
        const { prnt_sctn_id, sctn_id } = item;
        const sectionItem = sectionsMap[prnt_sctn_id];
        const pageContent = contentGenerator(sctn_id);

        sectionsMap[sctn_id].pageContent.push(...pageContent);
        if (sectionItem) {
          sectionItem.children.push(sectionsMap[sctn_id]);
        } else {
          result.push(sectionsMap[sctn_id]);
        }
      }
      return result;
    },
    [Page_id_input]
  );
  return (
    <>
      <SimpleDialog open={open} setOpen={setOpen} layout={layout} apidata={apidata} />
    </>
  );
}

SecondeLayout.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  Page_id_input: PropTypes.object,
  setOpen: PropTypes.func
};
