import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import GraphTest from '../GraphTest/GraphTest';

const ProgressBar = ({ chrt_id, apidata, layout, item }) => {
  const chart_data = useMemo(() => {
    if (chrt_id) {
      const api_data = apidata?.chart;
      let filterd_data = api_data?.filter((items) => items?.[chrt_id]);
      return filterd_data?.[0]?.[chrt_id];
    }
  }, [apidata]);

  // let q = [];
  // for (const i of chart_data?.chartTableData || []) {
  //   let obj = {};
  //   for (const j of childLayout?.[0]?.pageContent?.[0]?.chartDetail || []) {
  //     if (j.axis === 'y') {
  //       obj[j.fld_id] = i[j.fld_id];
  //     } else {
  //       obj[j.ttl] = i[j.fld_id];
  //     }
  //   }
  //   q.push(obj);
  // }

  let q = [];
  for (const i of chart_data?.chartTableData || []) {
    let obj = {};
    for (const j of item?.pageContent?.[0]?.chartDetail || []) {
      if (j.axis === 'y') {
        obj[j.fld_id] = i[j.fld_id];
      } else {
        obj[j.ttl] = i[j.fld_id];
      }
    }
    q.push(obj);
  }
  return (
    <>
      <GraphTest
        graphdata={q}
        chart_data={chart_data}
        apidata={apidata}
        layout={layout}
        item={item}
      />
    </>
  );
};

ProgressBar.propTypes = {
  childLayout: PropTypes.array,
  layout: PropTypes.object,
  chrt_id: PropTypes.string,
  setParent: PropTypes.func,
  apidata: PropTypes.object,
  item: PropTypes.object
};

export default ProgressBar;
