import React, { useMemo, memo, useCallback } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

const NestedTable = ({ content, apidata, setTableName }) => {
  const handleIconClick = (event, content, item) => {
    event.stopPropagation(); // Prevent the default sort action
    console.log('Icon clicked!', content, item);
    if (item.pm === 'p') {
      setTableName(item.p_tbl);
    } else if (item.pm === 'm') {
      setTableName(item.m_tbl);
    }
    // } else if (item.pm === '') {
    //   setTableName(content[0].m2_table);
    // }
    // was working here whiche minus has been clicked here.

    // Add your custom logic here
  };

  const chart_data = useMemo(() => {
    if (apidata?.got.length && content?.[0]?.own_table) {
      const table_id = content?.[0]?.own_table;
      const filtered_data = apidata.got.filter((items) => items?.[table_id]);
      return filtered_data?.[0]?.[table_id] || [];
    }
    return [];
  }, [content, apidata]);

  // const getUniqueBrands = useCallback((objects) => {
  //   const uniqueBrands = new Set();
  //   return objects.map((obj) => {
  //     if (!uniqueBrands.has(obj.brand)) {
  //       uniqueBrands.add(obj.brand);
  //       return obj;
  //     } else {
  //       return { ...obj, brand: '', sub_brand: '', item: '' };
  //     }
  //   });
  // }, []);

  const getUniqueObjects = useCallback((objects) => {
    const uniqueCombinations = new Set();
    return objects.map((obj) => {
      const key = `${obj.brand}-${obj.sub_brand?.toLowerCase()}-${obj.item}`;
      if (!uniqueCombinations.has(key)) {
        uniqueCombinations.add(key);
        return obj;
      } else {
        return { ...obj, brand: '', sub_brand: '', item: '' };
      }
    });
  }, []);

  const NestedTableremove = (data) => {
    // Create a Set to keep track of unique brands and sub-brands
    const seenBrands = new Set();
    const seenSubBrands = new Set();

    // Map over the array and modify the 'brand' and 'sub_brand' fields
    const result = data.map((item) => {
      const isBrandSeen = seenBrands.has(item.brand);
      const isSubBrandSeen = seenSubBrands.has(`${item.brand}-${item.sub_brand}`); // Unique sub-brand tracking per brand

      // If the brand has already been seen, replace it with an empty string
      const brandValue = isBrandSeen ? '' : item.brand;

      // If the sub-brand has already been seen, replace it with an empty string
      const subBrandValue = isSubBrandSeen ? '' : item.sub_brand;

      // Add brand and sub-brand to their respective sets
      seenBrands.add(item.brand);
      seenSubBrands.add(`${item.brand}-${item.sub_brand}`);

      return { ...item, brand: brandValue, sub_brand: subBrandValue };
    });

    return result;
  };

  const uniqueItems = NestedTableremove(chart_data);

  const uniqueObjects = getUniqueObjects(chart_data);
  const table_id = content?.[0]?.own_table;
  console.log('uniqueObjects...', chart_data, uniqueObjects, uniqueItems, table_id);

  const modifiedData = uniqueItems.map((row, index) => ({
    ...row,
    id: index // Or use a unique field from your data
  }));

  const column1s = useMemo(() => {
    if (!content || !content[0] || !content[0].tableData) {
      // Return an empty array if content or tableData is not available
      return [];
    }
    return content?.[0]?.tableData?.map((item, index) => ({
      field: item.fld_id,
      headerName: content?.[0]?.tableFieldData?.[index]?.fld_l,
      headerAlign: 'left',
      flex: 1,
      renderHeader: (params) => (
        <div>
          <Stack direction="column" alignItems="center">
            <Typography fontWeight="600" fontSize="0.8rem">
              {params.colDef.headerName}
            </Typography>
            {item.pm === 'p' && (
              <AddBoxOutlinedIcon
                sx={{ cursor: 'pointer', fontSize: '17px' }}
                onClick={(event) => {
                  handleIconClick(event, content, item);
                }}
              />
            )}
            {item.pm === 'm' && (
              <IndeterminateCheckBoxOutlinedIcon
                sx={{ cursor: 'pointer', fontSize: '17px' }}
                onClick={(event) => {
                  handleIconClick(event, content, item);
                }}
              />
            )}
          </Stack>
        </div>
      ),

      renderCell: (params) => {
        const textColor = item.text_color;
        const styling = params.row;
        const textColoring = styling[textColor] || '#000000';

        return (
          <div style={{ color: textColoring }}>
            <Stack direction="row">
              <Typography fontWeight="600" fontSize="0.8rem">
                {params.value}
              </Typography>
            </Stack>
          </div>
        );
      }
    }));
  }, [content]);
  const NestedTable = (content) => {
    return content?.[0]?.tableData?.[0]?.col_hdr_bg_colr;
  };

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={modifiedData || []}
        columns={column1s || []}
        // initialState={{
        //   pagination: {
        //     paginationModel: {
        //       pageSize: 5
        //     }
        //   }
        // }}
        rowHeight={30} // Set the height of the rows
        pageSizeOptions={[5]}
        checkboxSelection={false} // Disable checkboxes for row selection
        disableRowSelectionOnClick
        pagination={false}
        showCellVerticalBorder
        showColumnVerticalBorder
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '1px solid #E0E0E0',
            backgroundColor: () => NestedTable(content)
            // Border for column headers
          }

          // '& .MuiDataGrid-cell': {
          //   borderRight: '1px solid #E0E0E0', // Border for cells
          // },
        }}
      />
    </Box>
  );
};
NestedTable.propTypes = {
  apidata: PropTypes.object,
  content: PropTypes.object,
  chart_data: PropTypes.array,
  setTableName: PropTypes.func
};
export default memo(NestedTable);
