// top level imports
import PropTypes from 'prop-types';

// MUI
import { Drawer, Box, Tooltip, Button, IconButton, List, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// router imports
import { useNavigate } from 'react-router-dom';
import MobileNavItems from './MobileNavItems';

const drawerWidth = 240;

const MobileNav = ({ handleDrawerToggle, menuItems, mobileOpen }) => {
  const navigate = useNavigate();

  const drawer = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '49px'
        }}
      >
        <Tooltip title="go to landing page">
          <Button
            color="inherit"
            aria-label="go to landing page"
            edge="start"
            onClick={() => navigate(`/`)}
          >
            {/* <Typography>LCP</Typography> */}
            <></>
          </Button>
        </Tooltip>
        <Tooltip title="close menu">
          <IconButton
            color="inherit"
            aria-label="close drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Divider />
      <List>
        {menuItems.map((menu) => (
          <MobileNavItems
            key={menu.mnu_id}
            menuItem={menu}
            hasChildMenuListIcon={true}
            closeDrawer={handleDrawerToggle}
          />
        ))}
      </List>
    </Box>
  );

  return (
    <Box component="nav">
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

MobileNav.propTypes = {
  handleDrawerToggle: PropTypes.func,
  menuItems: PropTypes.array,
  mobileOpen: PropTypes.bool
};

export default MobileNav;
