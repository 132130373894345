import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
  Box,
  Button,
  Stack,
  Typography
} from '@mui/material';
import { LuFilterX } from 'react-icons/lu';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { GroupOfDropdownValuePassing } from '../../../features/counter/counterSlice';
import BasicMenu from '../../MuiMenu/Menu';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  }
};

const MultiDrop = ({
  fld_l,
  chart_data,
  setFilterData,
  setFilterKey,
  optionsToSelect,
  filterKey,
  updateFieldValues,
  fieldValues
}) => {
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();

  const chartFieldValues = [...new Set(chart_data?.map((object) => object?.[fld_l]))];
  const dropdownOptions = [...new Set(optionsToSelect?.map((object) => object?.[fld_l]))];

  const multiDropShowOptions = filterKey.includes(fld_l) ? dropdownOptions : chartFieldValues;

  const isAllSelected =
    multiDropShowOptions?.length > 0 && selected.length === multiDropShowOptions?.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelected(isAllSelected ? [] : multiDropShowOptions);
      return;
    }
    setSelected(value);
  };

  const handleChangeRedux = (selected, fieldName, options) => {
    if (selected.length === options.length) {
      setFilterKey([]);
      setFilterData([...selected]);
    } else {
      setFilterKey((prev) => [...prev, fieldName]);
      setFilterData((prev) => [...prev, ...selected]);
      console.log('chekinghere...', fieldName, selected);
    }
  };

  useEffect(() => {
    if (chart_data) {
      setSelected([...chartFieldValues]);
    }
  }, [chart_data]);

  const cancelfilterIcon = (fieldName) => {
    if (filterKey.length === 1) {
      setFilterData([]);
      dispatch(GroupOfDropdownValuePassing({}));
    }
    setFilterKey((prev) => prev.filter((item) => item !== fieldName));
  };

  useEffect(() => {
    updateFieldValues(fld_l, selected);
  }, [selected, fld_l]);

  return (
    <Box sx={{ backgroundColor: '#f5f5f5', padding: '3px', width: 'fit-content' }}>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Typography marginLeft="3px">{fld_l}</Typography>
        <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
          <Box>
            {filterKey.includes(fld_l) ? (
              <LuFilterX cursor="pointer" color="red" onClick={() => cancelfilterIcon(fld_l)} />
            ) : (
              <LuFilterX />
            )}
          </Box>
          <BasicMenu />
        </Stack>
      </Stack>
      <FormControl sx={{ width: 100 }} size="small">
        <InputLabel id="demo-multiple-checkbox-label">{}</InputLabel>
        <Select
          labelId="mutiple-select-label"
          multiple
          value={selected}
          onChange={handleChange}
          renderValue={(selected) => (isAllSelected ? 'All' : selected.join(', '))}
          MenuProps={MenuProps}
        >
          <MenuItem value="all" sx={{ padding: '0' }}>
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selected?.length > 0 && selected?.length < multiDropShowOptions?.length
                }
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>

          {multiDropShowOptions?.map((option) => (
            <MenuItem key={option} value={option} sx={{ padding: '0' }}>
              <ListItemIcon>
                <Checkbox checked={selected.includes(option)} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button variant="outlined" sx={{ color: '#000000' }} onClick={() => setSelected([])}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{ color: '#000000' }}
              onClick={() => handleChangeRedux(selected, fld_l, multiDropShowOptions, fieldValues)}
            >
              Apply
            </Button>
          </Stack>
        </Select>
      </FormControl>
    </Box>
  );
};

export default React.memo(MultiDrop);

MultiDrop.propTypes = {
  fld_l: PropTypes.string.isRequired,
  chart_data: PropTypes.array.isRequired,
  setFilterData: PropTypes.func.isRequired,
  setFilterKey: PropTypes.func.isRequired,
  optionsToSelect: PropTypes.array.isRequired,
  filterKey: PropTypes.array.isRequired,
  storeddata: PropTypes.object,
  myObject: PropTypes.object,
  updateFieldValues: PropTypes.func,
  fieldValues: PropTypes.object
};
