import { gql } from '@apollo/client';

export const SCENARIOS = gql`
  query Scenarios {
    scenarios {
      client
      scn_id
      scn_nm
      sscn_nm
      crusr_id
      cr_dt
      chusr_id
      ch_dt
      id
    }
  }
`;

export const CREATE_SCENARIO = gql`
  mutation CreateScenario($client: String!, $scn_id: String!, $scn_nm: String!, $sscn_nm: String!) {
    createScenario(client: $client, scn_id: $scn_id, scn_nm: $scn_nm, sscn_nm: $sscn_nm) {
      client
      scn_id
      scn_nm
      sscn_nm
    }
  }
`;

export const UPDATE_SCENARIO = gql`
  mutation UpdateScenario(
    $id: ID!
    $client: String!
    $scn_id: String!
    $scn_nm: String!
    $sscn_nm: String!
  ) {
    updateScenario(id: $id, client: $client, scn_id: $scn_id, scn_nm: $scn_nm, sscn_nm: $sscn_nm) {
      id
    }
  }
`;

export const DELETE_SCENARIO = gql`
  mutation DeleteScenario($ids: [ID!]!) {
    deleteScenario(ids: $ids) {
      success
    }
  }
`;

export const CHART_BOTTLENECKRESOURCES = gql`
  query BottleneckResourceList {
    bottleneckResourceList {
      client
      plrun_id
      loc_id
      res_b
      d_dl_v
      d_um_v
      id
    }
  }
`;
