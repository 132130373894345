import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
const array_data = [
  { name: 'GP', data: [1, 20] },
  { name: 'PHYSICIAN', data: [0, 20] },
  { name: 'PEDIATRICIAN', data: [4, 12] },
  { name: 'GYNAECOLOGIST', data: [6, 8] },
  { name: 'DENTIST', data: [0, 10] },
  { name: 'DIABETIC SPECIALIST', data: [0, 9] },
  { name: 'ORTHOPEDIC', data: [0, 5] },

  { name: 'MBBS GP', data: [0, 5] },
  { name: 'ENT SURGEOON', data: [0, 3] },
  { name: 'SURGEOON', data: [0, 3] },
  { name: 'GYNEC-IVF', data: [1, 20] },
  { name: 'DERMATOLOGIST', data: [2, 1] },
  { name: 'GP-GYN', data: [0, 2] },
  { name: 'ENT', data: [0, 2] },
  { name: 'CARDIOLOGIST', data: [0, 2] }
];

function LinearProgressWithLabel({ value, name, index }) {
  // console.log('value...', value, name);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '30%', mr: 1 }}>
        {index === 0 && <Box sx={{ marginTop: '1em' }}></Box>}

        <Typography>{name}</Typography>
      </Box>
      <Box sx={{ width: '30%', mr: 1 }}>
        {index === 0 && (
          <Typography variant="body2" color="text.secondary">
            v1
          </Typography>
        )}
        <LinearProgress
          variant="determinate"
          value={value[0]}
          sx={{ height: '18px', backgroundColor: 'transparent', borderWidth: '1px' }}
        />
      </Box>

      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {value[0]}
        </Typography>
      </Box>

      <Box sx={{ width: '30%', mr: 1 }}>
        {index === 0 && (
          <Typography variant="body2" color="text.secondary">
            v2
          </Typography>
        )}

        <LinearProgress
          variant="determinate"
          value={value[1]}
          sx={{ height: '18px', backgroundColor: 'transparent', borderWidth: '1px' }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {value[1]}
        </Typography>
      </Box>

      {/* <Box sx={{ width: '30%', mr: 1 }}>
        <Typography>PEDIATRICIAN</Typography>
      </Box> */}

      {/* <Box sx={{ width: '30%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{ height: '15px', backgroundColor: 'white' }}
        />
      </Box> */}

      {/* <Box sx={{ minWidth: 5 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}`}</Typography>
      </Box> */}

      {/* <Box sx={{ width: '30%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={6}
          sx={{ height: '15px', backgroundColor: 'transparent', borderWidth: '1px' }}
        />
      </Box> */}

      {/* <Box sx={{ minWidth: 5 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}`}</Typography>
      </Box> */}
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};

export default function MultiColumnProgressBar() {
  //   const [progress, setProgress] = React.useState(10);

  //   React.useEffect(() => {
  //     const timer = setInterval(() => {
  //       setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
  //     }, 800);
  //     return () => {
  //       clearInterval(timer);
  //     };
  //   }, []);

  return (
    <Box sx={{ width: '100%' }}>
      {array_data.map((item, index) => (
        <LinearProgressWithLabel value={item.data} name={item.name} key={index} index={index} />
      ))}
    </Box>
  );
}
