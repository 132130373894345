import { Suspense, useEffect, useState } from 'react';
import { useParams, ScrollRestoration, useLoaderData, Await } from 'react-router-dom';
import DynamicLayout from '../../layouts/DynamicLayout/DynamicLayout';
import Loader from '../../components/Loader/Loader';
import queryString from 'query-string';
import { useQuery } from '@apollo/client';
import { SCENARIOS } from '../../graphql';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Http from '../../utils/http';
import axios from 'axios';

const AppPage = () => {
  const { slug } = useParams();
  const loaderData = useLoaderData();

  const [pageData, setPageData] = useState([]);
  const { data: graphqlData, refetch: refetchGraphqlData } = useQuery(SCENARIOS, { skip: true });
  const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);
  const dropDownValues = useSelector((state) => state.counter.getDropDownValue);
  const dropDownValuesYear = useSelector((state) => state.counter.year);
  const dropDownValuesmonth = useSelector((state) => state.counter.month);
  const dropDownValuesMrName = useSelector((state) => state.counter.mrName);
  const dropDownValuesamount = useSelector((state) => state.counter.amount);
  const dropDownValuemonthSingle = useSelector((state) => state.counter.monthSingle);
  const dropDownValuesrange = useSelector((state) => state.counter.range);
  const dropDownValuesTopBottomPerformers = useSelector(
    (state) => state.counter.TopBottomPerformers
  );

  const godValue = useSelector((state) => state.counter.groupofdropDownDefaultNull);
  const godFilterValue = useSelector((state) => state.counter.groupofdropDownValuePass);
  const ccaMonthValue = useSelector((state) => state.counter.ccaMonth);
  const ccaRoleValue = useSelector((state) => state.counter.ccaRole);
  const DefaultRoleNMonth = useSelector((state) => state.counter.DefaultRoleNMonth);

  const val_volValue = useSelector((state) => state.counter.val_vol);
  const role_id = localStorage.getItem('role_id');
  const DefaultMrNameLength = localStorage.getItem('MrName_length');
  const SelectedtMrNameLength = localStorage.getItem('MrName_length_onchange');

  const fetch_user_detail = async () => {
    const userEmail = localStorage.getItem('email');
    try {
      const response = await Http.post('/fetch-user-detail', {
        email: userEmail
      });
      if (!response.data?.error) {
        const userData = response.data;
        const emp_id = userData?.data?.emp_id;
        const emp_name = userData?.data?.emp_name;
        localStorage.removeItem('emp_id');
        localStorage.removeItem('emp_name');
        localStorage.setItem('emp_id', emp_id);
        localStorage.setItem('emp_name', emp_name);
      }
    } catch (e) {
      console.log(e?.message);
    }
  };

  const oneLoginme = async () => {
    const response1 = queryString.parse(window.location.hash);
    try {
      const response = await axios.get('https://mankindpharma-sandbox.onelogin.com/oidc/2/me', {
        headers: {
          Authorization: `Bearer ${response1?.access_token}`
        }
      });
      if (!response.data?.error) {
        const Medata = response?.data?.email;
        localStorage.removeItem('email');
        localStorage.setItem('email', Medata);
      }
    } catch (e) {
      console.log(e?.message);
    }
  };

  useEffect(() => {
    const ssologin = localStorage.getItem('SSOlogin');

    if (ssologin === 'SSOlogin') {
      oneLoginme();
      fetch_user_detail();
    }
    // Parse the id_token from the URL
    // const response1 = queryString.parse(window.location.hash);
    // console.log("response1",response1)
    // Fetch user info using the id_token
    // fetch('https://mankindpharma-sandbox.onelogin.com/oidc/2/me', {
    //   headers: {
    //     Authorization: `Bearer ${response1?.access_token}`
    //   }
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     fetch_user_detail(data);
    //   })
    //   .catch((error) => console.error('Error fetching user info:', error));
    if (slug && graphqlData) {
      setPageData(graphqlData.scenarios);
    }
  }, [slug, graphqlData]);

  function getCurrentMonthName() {
    const monthNames = {
      0: '01',
      1: '02',
      2: '03',
      3: '04',
      4: '05',
      5: '06',
      6: '07',
      7: '08',
      8: '09',
      9: '10',
      10: '11',
      11: '12'
    };
    const currentDate = new Date();
    return [monthNames[currentDate.getMonth()]];
  }

  const AppPageDropdown = (DefaultMrNameLength, SelectedtMrNameLength) => {
    if (parseInt(DefaultMrNameLength) === parseInt(SelectedtMrNameLength)) {
      return 'y';
    } else {
      return 'n';
    }
  };

  const GroupOfDropDown = (godValue, godFilterValue) => {
    if (godFilterValue && godFilterValue[0]) {
      if (Object.entries(godFilterValue[0]).length !== 0) {
        return godFilterValue[0];
      }
    }
    if (godValue && godValue[0]) {
      return godValue?.[0];
    }
  };

  console.log('DefaultRoleNMonth...', DefaultRoleNMonth);

  const Page_a2_ApiCall = async (pgid) => {
    const emp_id = localStorage.getItem('emp_id');
    const currentYear = new Date().getFullYear();

    try {
      const response = await Http.post('/fetch-all-page-data', {
        a_id: 'a2',
        pg_id: pgid,
        emp_id: `${emp_id}`,
        week_number: dropDownValues.toString(),
        field_values: {},
        action: {},
        sel_table: {},
        input_values: hyperdata?.length ? hyperdata?.[2]?.BarInfo : {},
        year: dropDownValuesYear.length ? dropDownValuesYear : [`${currentYear}`],
        month: dropDownValuesmonth.length ? dropDownValuesmonth : getCurrentMonthName(),
        MRName: dropDownValuesMrName?.length ? dropDownValuesMrName : [],
        amount: dropDownValuesamount[0]?.length ? dropDownValuesamount.toString() : 'Lacs',
        month_single: dropDownValuemonthSingle?.length
          ? dropDownValuemonthSingle
          : getCurrentMonthName(),
        top_bottom_performers: dropDownValuesTopBottomPerformers.toString(),
        range: parseInt(dropDownValuesrange),
        role_id: role_id,
        isAllMRSelectedValue: AppPageDropdown(DefaultMrNameLength, SelectedtMrNameLength),
        group_filter: GroupOfDropDown(godValue, godFilterValue),
        ccaMonth: ccaMonthValue,
        ccaRole: ccaRoleValue,
        val_vol: val_volValue.toString()
      });
      if (!response.data?.error) {
        return response?.data?.data;
      }
    } catch (e) {
      console.log(e?.message);
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <Await resolve={loaderData.bundle}>
        {(routerData) => {
          const [pageContent, pageContentData, pageSections] = routerData;
          let pageStructure = [];
          //  using localStorage menu
          let menuDataString = localStorage.getItem('MenuData');
          let menuData = JSON.parse(menuDataString);
          const PageIDs = menuData?.find((item) => item?.slug === slug)?.pg_id || '';
          // const pageId = menu?.data.data?.find((item) => item?.slug === slug)?.pg_id || '';
          const PageID =
            hyperdata.length === 3 || hyperdata.length === 2
              ? hyperdata?.[0]?.hyperPage_id
              : hyperdata.length === 1
              ? hyperdata?.[0]?.DefaultBrowserPageId
              : PageIDs;

          if (!pageContent?.data?.error && !pageContentData?.data?.error) {
            pageStructure = pageContentData?.data?.data?.filter(
              (item) => item?.pageData?.[0]?.pg_id === PageID
            );
          } else {
            if (pageContent?.data?.error) {
              toast.error(pageContent?.data?.message);
            } else {
              toast.error(pageContentData?.data?.message);
            }
          }
          return (
            <DynamicLayout
              pageContentObj={pageStructure}
              pageData={pageData}
              refetchPageData={refetchGraphqlData}
              targetPageId={PageID}
              pageSections={pageSections?.data?.data}
              // oneLoginme={oneLoginme}
              // fetch_user_detail={fetch_user_detail}
              Page_a2_ApiCall={Page_a2_ApiCall}
            />
          );
        }}
      </Await>
      <ScrollRestoration
        getKey={(location) => {
          return location.pathname;
        }}
      />
    </Suspense>
  );
};

export default AppPage;
