import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Card, CardContent } from '@mui/material';

export default function LabTabs() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box id="box here">
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Card variant="outlined" sx={{ mr: 1, borderRadius: '26px' }}>
              <CardContent>
                <Typography variant="body2">Mini Card 1</Typography>
              </CardContent>
            </Card>
            <Tab label="Item One" value="1" />

            <Box sx={{ width: 50 }} />

            <Card variant="outlined" sx={{ mr: 1, borderRadius: '26px' }}>
              <CardContent>
                <Typography variant="body2">Mini Card 1</Typography>
              </CardContent>
            </Card>
            <Tab label="Item Two" value="2" />
            <Box sx={{ width: 40 }} />

            <Card variant="outlined" sx={{ mr: 1, borderRadius: '26px' }}>
              <CardContent>
                <Typography variant="body2">Mini Card 1</Typography>
              </CardContent>
            </Card>
            <Tab label="Item Three" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">Item One</TabPanel>
        <TabPanel value="2">Item Two</TabPanel>
        <TabPanel value="3">Item Three</TabPanel>
      </TabContext>
    </Box>
  );
}
