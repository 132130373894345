import React, { useMemo, memo, useEffect, useState, useRef } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import PropTypes from 'prop-types';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const MrtTable = ({ apidata, content }) => {
  const [chart_data, setChartData] = useState([]);
  const previousChartDataRef = useRef();

  useEffect(() => {
    if (apidata?.allTablesData.length && content?.tbl_id) {
      const table_id = content.tbl_id;
      const filtered_data = apidata.allTablesData.filter((items) => items?.[table_id]);
      const newChartData = filtered_data?.[0]?.[table_id] || [];

      // Check with previous data to prevent unnecessary updates
      if (JSON.stringify(previousChartDataRef.current) !== JSON.stringify(newChartData)) {
        previousChartDataRef.current = newChartData;
        setChartData(newChartData);
      }
    }
  }, [apidata]);

  const columns = useMemo(() => {
    // one time rendering
    return content?.tableData?.map((item, index) => ({
      accessorKey: item.fld_id,
      header: content?.tableFieldData?.[index]?.fld_l,
      textColor: item?.text_color,
      bgColor: item?.bg_color,
      icon: item?.icon,
      columnHeaderColor: item?.col_hdr_bg_colr,
      columnHeaderTextColor: item?.col_hdr_text_colr,

      Cell: ({ cell }) => {
        const textColor = cell?.column?.columnDef?.textColor; //column data
        const styling = cell?.row?.original; //row data
        const icon = cell?.column?.columnDef?.icon;
        const TextColoring = styling[textColor] ? styling[textColor] : '#000000';

        if (textColor || styling || icon) {
          return (
            <div
              style={{
                color: TextColoring
                // padding: '0.5em'
              }}
            >
              <Stack direction="row">
                <Typography fontWeight="600" fontSize="0.8rem">
                  {cell.getValue()}
                </Typography>
                {styling[icon] === 'up' ? (
                  <ArrowDropUpOutlinedIcon />
                ) : styling[icon] === 'down' ? (
                  <ArrowDropDownIcon />
                ) : null}
              </Stack>
            </div>
          );
        }
      }
    }));
  }, []);

  // const shouldRemoveBorder = (cellValue) => {
  //   // Check if the cell value is empty
  //   return cellValue === '';
  // };

  // const shouldRemoveBorder = useMemo(() => {
  //   return (cellValue) => {
  //     // Check if the cell value is empty
  //     return cellValue === '';
  //   };
  // }, [content?.tbl_id]);

  const shouldHaveBackgroundColor = useMemo(() => {
    return (cell) => {
      const bgColor = cell?.column?.columnDef?.bgColor;
      const styling = cell?.row?.original;
      const bgColoring = styling[bgColor];
      return bgColoring;
    };
  }, [content]);

  const shouldHaveColumnStyle = useMemo(() => {
    return (cell) => {
      const bgColor = cell?.column?.columnDef?.columnHeaderColor;
      const textColor = cell?.column?.columnDef?.columnHeaderTextColor;
      return {
        sx: {
          fontSize: '0.8rem',
          backgroundColor: bgColor ? '#1995AD' : '#FFFFFF',
          height: '80px',
          borderRight: '0.5px solid #E0E0E0',
          color: textColor ? textColor : '#000000',
          fontWeight: 'bold'
        }
      };
    };
  }, [content]);

  const table = useMaterialReactTable({
    columns,
    data: chart_data || [],
    enableExpandAll: false, // Hide expand all double arrow in column header
    enableExpanding: false,
    filterFromLeafRows: true, // Apply filtering to all rows instead of just parent rows
    enableColumnFilters: false,
    enableSorting: false,
    enableColumnActions: false,
    getSubRows: (row) => row.subRows || [], // Ensure all rows have sub-rows
    initialState: { expanded: true }, // Expand all rows by default
    paginateExpandedRows: false, // When rows are expanded, do not count sub-rows as number of rows on the page towards pagination
    enableGlobalFilter: false, // Hide global filter bar
    enableTopToolbar: false,
    enablePagination: false,
    enableRowVirtualization: true,
    // muiTableContainerProps: { sx: { maxHeight: '700px' } }, // enable verticle scroll bar in table
    // enableColumnResizing: true,
    muiTableBodyCellProps: ({ cell }) => ({
      sx: {
        // borderBottom: shouldRemoveBorder(cell.getValue()) ? 'none' : undefined,
        // height: '40px',
        border: '0.2px solid #1995AD',
        backgroundColor: shouldHaveBackgroundColor(cell) ? '#F9D23C' : '#FFFFFF',
        fontWeight: 'bold',
        whiteSpace: 'noraml',
        wordBreak: 'break-word'
      }
    }),
    muiTableHeadCellProps: shouldHaveColumnStyle
  });

  return <MaterialReactTable table={{ ...table }} />;
};
MrtTable.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object,
  cell: PropTypes.shape({
    column: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    getValue: PropTypes.func.isRequired
    // Add other expected properties with appropriate types
  }).isRequired
};

export default memo(MrtTable);
