import { Button, Container, IconButton, Stack, TextField, Typography } from '@mui/material';
import GuestLayout from '../../layouts/GuestLayout/GuestLayout';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import Http from '../../utils/http';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  /**
   * state declarations
   */
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [repeatPasswordVisible, setRepeatPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const registerUser = async (values) => {
    setLoading(true);
    try {
      const response = await Http.post('register', values);
      if (!response.data.error) {
        toast.success('User registered successfully! Please login to proceed.');
        navigate('/login');
      } else {
        toast.error(response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * formik initialization
   */
  const formik = useFormik({
    initialValues: {
      client: '',
      name: '',
      email: '',
      password: '',
      password_confirmation: ''
    },
    validationSchema: Yup.object().shape({
      client: Yup.string().required('Please enter your Client ID'),
      name: Yup.string().required('Please enter your name'),
      email: Yup.string().email('Please enter valid email').required('Please enter your email'),
      password: Yup.string().required('Please enter password'),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Please confirm your password')
    }),
    onSubmit: (values, { resetForm }) => {
      registerUser(values);
      resetForm();
    }
  });

  return (
    <GuestLayout>
      <Container maxWidth="sm">
        <Stack component="form" noValidate spacing={2} onSubmit={formik.handleSubmit}>
          <Typography variant="h5" gutterBottom textAlign="center">
            Create your account
          </Typography>
          <TextField
            label="Client ID"
            required
            name="client"
            value={formik.values.client}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.touched.client && formik.errors.client}
            error={formik.touched.client && Boolean(formik.errors.client)}
          />
          <TextField
            label="Name"
            required
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.touched.name && formik.errors.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
          />
          <TextField
            label="Email"
            type="email"
            name="email"
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.touched.email && formik.errors.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          <TextField
            label="Password"
            type={passwordVisible ? 'text' : 'password'}
            name="password"
            required
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setPasswordVisible((prev) => !prev)}>
                  {passwordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              )
            }}
            helperText={formik.touched.password && formik.errors.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
          />
          <TextField
            label="Repeat Password"
            type={repeatPasswordVisible ? 'text' : 'password'}
            name="password_confirmation"
            required
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setRepeatPasswordVisible((prev) => !prev)}>
                  {repeatPasswordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              )
            }}
            helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
            error={
              formik.touched.password_confirmation && Boolean(formik.errors.password_confirmation)
            }
          />
          <LoadingButton variant="contained" type="submit" loading={loading}>
            Register
          </LoadingButton>
          <Button onClick={() => navigate('/login')}>Have an account? Login</Button>
        </Stack>
      </Container>
    </GuestLayout>
  );
};

export default Register;
