import PropTypes from 'prop-types';
import { Container } from '@mui/material';
// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Http from '../../utils/http';
import AppHeader from './AppHeader';

const AppLayout = ({ children }) => {
  // const navigate = useNavigate();
  // const accessToken = localStorage.getItem('access_token');

  // useEffect(() => {
  //   // if an access token doesn't exist in the local storage, or if login session has expired, redirect the user to login
  //   if (!accessToken || localStorage.getItem('expiry') < Date.now()) {
  //     navigate('/login');
  //   } else {
  //     Http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  //   }
  // }, [accessToken]);

  return (
    <>
      <AppHeader />
      <Container sx={{ m: 0, mt: { xs: 2, sm: 0 } }} maxWidth="100%">
        {children}
      </Container>
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node
};

export default AppLayout;
