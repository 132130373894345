import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Button, Container, Stack } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import { ToGetDropDownValue } from '../../features/counter/counterSlice';
import { emptyarr1 } from '../../features/counter/counterSlice';
import { useDispatch } from 'react-redux';
import { RemoveProgressBarHyperLinkEmpty } from '../../features/counter/counterSlice';
import { ToEmptyDropDownValueMrName } from '../../features/counter/counterSlice';
import { ToGetDropDownValueAmount } from '../../features/counter/counterSlice';
import { ToGetDropDownValueTopBottomPerformers } from '../../features/counter/counterSlice';
export default function CardMUILogoutPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectToLogout = () => {
    return navigate('/login');
  };

  useEffect(() => {
    dispatch(ToGetDropDownValue(''));
    dispatch(emptyarr1());
    dispatch(RemoveProgressBarHyperLinkEmpty());
    dispatch(ToEmptyDropDownValueMrName());
    dispatch(ToGetDropDownValueAmount());
    dispatch(ToGetDropDownValueTopBottomPerformers());
  }, []);

  // const dropDownValues = useSelector((state) => state.counter.getDropDownValue);
  // console.log('dropDownValues-logout', dropDownValues);
  return (
    <Container sx={{ height: '100vh' }}>
      <Stack justifyContent="center" height="inherit">
        <Container maxWidth="sm">
          <Card>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Thank you for visiting this site!
                </Typography>
                <Typography variant="h5">See you again soon!</Typography>
              </CardContent>
              <CardActions>
                <Button onClick={redirectToLogout}>Login</Button>
              </CardActions>
            </CardActionArea>
          </Card>
        </Container>
      </Stack>
    </Container>
  );
}
