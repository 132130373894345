import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { useState } from 'react';
import { Box } from '@mui/material';
import PoolName from './PoolName';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9)
];
const rowkeys = ['name', 'calories', 'fat', 'carbs', 'protein'];

export default function BaseTable() {
  const [buttonClick, setButtonClick] = useState({ level1: false, level2: false, level3: false });

  console.log('base...');
  return (
    <>
      {!buttonClick.level1 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              {/* <TableRow>
            <TableCell align="center" colSpan={2}>
              Country
            </TableCell>
            <TableCell align="center" colSpan={3}>
              Details
            </TableCell>
          </TableRow> */}

              <TableRow>
                <TableCell>
                  Dessert (100g serving)
                  <Box
                    component="span"
                    onClick={() => {
                      setButtonClick((prev) => ({ ...prev, level1: true }));
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <AddBoxOutlinedIcon sx={{ position: 'absolute' }} />
                  </Box>
                </TableCell>
                <TableCell align="start">Calories</TableCell>
                <TableCell align="start">Fat&nbsp;(g)</TableCell>
                <TableCell align="start">Carbs&nbsp;(g)</TableCell>
                <TableCell align="start">Protein&nbsp;(g)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((item) => (
                <>
                  <TableRow key={item} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    {rowkeys?.map((child) => (
                      <>
                        <TableCell align="start">{item[child]}</TableCell>
                      </>
                    ))}
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {buttonClick.level1 && <PoolName buttonClick={buttonClick} setButtonClick={setButtonClick} />}
    </>
  );
}
