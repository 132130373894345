import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Container, Stack } from '@mui/material';

export default function InvalidUser() {
  // const dropDownValues = useSelector((state) => state.counter.getDropDownValue);
  // console.log('dropDownValues-logout', dropDownValues);
  return (
    <Container sx={{ height: '100vh' }}>
      <Stack justifyContent="center" height="inherit">
        <Container maxWidth="sm">
          <Card>
            <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Invalid User
                </Typography>
                <Typography variant="h5">Please Login!</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Container>
      </Stack>
    </Container>
  );
}
