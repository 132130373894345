import { useState, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton } from '@mui/material';
// import SettingsIcon from '@mui/icons-material/Settings';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import PropTypes from 'prop-types';
import { myObject } from '../../AllTestingComponents/MRTTable/Dummydata';
// import InsentiveTableSs from './InsentiveTableSs';
// import InsentiveTablePack from './InsentiveTablePack';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText
} from '@mui/material';

const data = [
  {
    // name: {
    //   firstName: 'John',
    //   lastName: 'Doe'
    // },
    // address: '261 Erdman Ford',
    // city: 'East Daphne',
    // state: 'Kentucky',
    // substate: 'A',
    // population: '11',
    // city1: 'y',
    // test: 'test',
    subRows: [
      {
        name: {
          firstName: 'John2Testing',
          lastName: 'DoeTesting'
        },
        address: '261 Erdman FordTesting',
        city: 'East DaphneTesting',
        state: 'KentuckyTesting',
        substate: 'ATesting',
        population: '11Testing',
        city1: 'yTesting',
        test: 'testTesting',
        subRows: [
          {
            name: {
              firstName: 'John3Testing',
              lastName: 'DoeTesting'
            },
            address: '261 Erdman FordTesting',
            city: 'East DaphneTesting',
            state: 'KentuckyTesting',
            substate: 'ATesting',
            population: '11Testing',
            city1: 'yTesting',
            test: 'testTesting'
          }
        ]
      }
    ]
  },
  {
    name: {
      firstName: 'Jane',
      lastName: 'Doe'
    },
    address: '769 Dominic Grove',
    city: 'Columbus',
    state: 'Ohio',
    substate: 'B',
    population: '22',
    city1: 'n',
    test: 'test',
    subRows: []
  },
  {
    name: {
      firstName: 'Joe',
      lastName: 'Doe'
    },
    address: '566 Brakus Inlet',
    city: 'South Linda',
    state: 'West Virginia',
    substate: 'C',
    population: '33',
    city1: 'y',
    test: 'test',
    subRows: []
  },
  {
    name: {
      firstName: 'Kevin',
      lastName: 'Vandy'
    },
    address: '722 Emie Stream',
    city: 'Lincoln',
    state: 'Nebraska',
    substate: 'E',
    population: '44',
    city1: 'y',
    test: 'test',
    subRows: []
  },
  {
    name: {
      firstName: 'Joshua',
      lastName: 'Rolluffs'
    },
    address: '32188 Larkin Turnpike',
    city: 'Charleston',
    state: 'South Carolina',
    substate: 'W',
    population: '66',
    city1: 'y',
    test: 'test',
    subRows: []
  }
];

const IncentiveTable = () => {
  const allColumns = useMemo(
    () => [
      {
        accessorKey: 'name.firstName', //access nested data with dot notation
        header: 'First Name',
        size: 100,
        enablePinning: true,
        Header: (props) => <CustomHeader column={props.column} props={props} ShowIcon={true} />
      },
      {
        accessorKey: 'name.lastName',
        header: 'Last Name',
        size: 150,
        // Header: (props) => <CustomHeader column={props.column} props={props} ShowIcon={false} />
        Header: ({ column }) => (
          <div>
            <button onClick={() => handleExpandAllRows()}>+</button>
            {column.id}
          </div>
        )
      },
      {
        accessorKey: 'address', //normal accessorKey
        header: 'Address',
        size: 200,
        Header: (props) => <CustomHeader column={props.column} props={props} ShowIcon={true} />
      },
      {
        accessorKey: 'city',
        header: 'City',
        size: 150,
        Header: (props) => <CustomHeader column={props.column} props={props} ShowIcon={false} />
      },
      {
        accessorKey: 'state',
        header: 'State',
        size: 150,
        Header: (props) => <CustomHeader column={props.column} props={props} ShowIcon={false} />
      },
      {
        accessorKey: 'substate',
        header: 'Substate',
        size: 150,
        Header: (props) => <CustomHeader column={props.column} props={props} ShowIcon={false} />
      },
      {
        accessorKey: 'population',
        header: 'Population',
        size: 150,
        Header: (props) => <CustomHeader column={props.column} props={props} ShowIcon={false} />
      },
      {
        accessorKey: 'city1',
        header: 'City1',
        size: 150,
        Header: (props) => <CustomHeader column={props.column} props={props} ShowIcon={false} />
      }
    ],
    []
  );

  const handleExpandAllRows = () => {
    table.toggleAllRowsExpanded();
  };

  const handleColumnToggle = (event) => {
    const value1 = event.target.value;
    if (value1[value1.length - 1] === 'all') {
      setVisibleColumns(visibleColumns.length === allColumns.length ? [] : allColumns);
      return;
    }
    const {
      target: { value }
    } = event;
    setVisibleColumns(allColumns.filter((column) => value.includes(column.header)));
  };

  const [visibleColumns, setVisibleColumns] = useState(allColumns);

  const [columnVisibility, setColumnVisibility] = useState({
    address: false,
    city: false
  });

  const isAllSelected = allColumns.length > 0 && visibleColumns.length === allColumns.length;

  const CustomHeader = ({ column, ShowIcon }) => {
    const handleIconClick = (event, action) => {
      event.stopPropagation(); // Stop event propagation to prevent sorting
      action();

      if (column.id === 'name.firstName') {
        setColumnVisibility((prev) => {
          if (column.id === 'name.firstName') {
            return { ...prev, address: !prev.address, city: false };
          }
          return prev;
        });
      } else if (column.id === 'address') {
        setColumnVisibility((prev) => {
          if (column.id === 'address') {
            return {
              ...prev,
              address: prev.address,
              city: !prev.city // Toggle the visibility of the address column
            };
          }
          return prev;
        });
      }
    };

    const ExampleComponent = (ID) => {
      console.log('Modified object:', myObject);
      if (ID === 'name.firstName') {
        myObject.toggle = !myObject.toggle;
        if (myObject.address === true) {
          myObject.address = false;
        }
      } else if (ID === 'address') {
        myObject.address = !myObject.address;
      }
      // Perform actions or logic with the modified object
      // For example, you can use the modified object to fetch data, update state, etc.

      return null; // No JSX used here
    };

    console.log('objectexport...', myObject);
    return (
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <span>{column.columnDef.header}</span>
        {ShowIcon && (
          <IconButton
            size="small"
            onClick={(event) =>
              handleIconClick(event, () => {
                console.log('Add icon clicked');
              })
            }
          >
            {column.id === 'name.firstName' && myObject.toggle === false ? (
              <AddBoxOutlinedIcon
                fontSize="small"
                onClick={() => {
                  ExampleComponent(column.id);
                }}
              />
            ) : column.id === 'name.firstName' ? (
              <IndeterminateCheckBoxOutlinedIcon
                onClick={() => {
                  ExampleComponent(column.id);
                }}
                fontSize="small"
              />
            ) : null}

            {column.id === 'address' && myObject.address === false ? (
              <AddBoxOutlinedIcon
                fontSize="small"
                onClick={() => {
                  ExampleComponent(column.id);
                }}
              />
            ) : column.id === 'address' ? (
              <IndeterminateCheckBoxOutlinedIcon
                fontSize="small"
                onClick={() => {
                  ExampleComponent(column.id);
                }}
              />
            ) : null}

            {column.id === 'name.lastName' ? (
              <AddBoxOutlinedIcon
                fontSize="small"
                onClick={() => {
                  ExampleComponent(column.id);
                }}
              />
            ) : null}
          </IconButton>
        )}
      </div>
    );
  };

  //should be memoized or stable
  const table = useMaterialReactTable({
    columns: visibleColumns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnPinning: true,
    enableSorting: false,
    // enableExpandAll: false,
    // enableExpanding: true,
    enableColumnActions: false,
    getSubRows: (row) => row.subRows, //default
    paginateExpandedRows: false,
    initialState: {
      columnPinning: { left: ['name.firstName', 'address', 'city'] },
      columnVisibility,
      expanded: false
    },
    state: { columnVisibility }
  });

  return (
    <Box>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Columns</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={visibleColumns
            .filter((item) => item.accessorKey !== 'name')
            .map((col) => col.header)}
          onChange={handleColumnToggle}
          input={<OutlinedInput label="Columns" />}
          renderValue={(selected) => selected.join(', ')}
        >
          <MenuItem value="all">
            <Checkbox
              checked={isAllSelected}
              indeterminate={visibleColumns.length > 0 && visibleColumns.length < allColumns.length}
            />
            <ListItemText primary="All" />
          </MenuItem>
          {allColumns
            .filter((item) => item.accessorKey !== 'name')
            .map((column) => (
              <MenuItem key={column.accessorKey} value={column.header}>
                <Checkbox
                  checked={visibleColumns.some((col) => col.accessorKey === column.accessorKey)}
                />
                <ListItemText primary={column.header} />
              </MenuItem>
            ))}

          <Stack direction="row" spacing={2} justifyContent="center">
            <Button variant="outlined" sx={{ color: '#000000' }} onClick={() => {}}>
              Cancel
            </Button>
            <Button variant="outlined" sx={{ color: '#000000' }} onClick={() => {}}>
              Apply
            </Button>
          </Stack>
        </Select>
      </FormControl>
      <MaterialReactTable table={table} />
    </Box>
    // ||
    // (showTable.TableSs && (
    //   <InsentiveTableSs
    //     setShowTable={setShowTable}
    //     showTable={showTable}
    //     PinningCulumn={['name.firstName', 'name.lastName']}
    //   />
    // )) ||
    // (showTable.TablePack && (
    //   <InsentiveTablePack
    //     setShowTable={setShowTable}
    //     showTable={showTable}
    //     PinningCulumn={['name.firstName', 'name.lastName', 'address']}
    //   />
    // ))
  );
};

IncentiveTable.propTypes = {
  column: PropTypes.shape({
    columnDef: PropTypes.shape({
      header: PropTypes.string.isRequired // Required string
    }).isRequired,
    id: PropTypes.string.isRequired // Required object
  }).isRequired, // Required object
  props: PropTypes.object,
  ShowIcon: PropTypes.bool,
  ColumnObject: PropTypes.func
};

export default IncentiveTable;
