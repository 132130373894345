import React from 'react';
import PropTypes from 'prop-types';
// import { CHART_BOTTLENECKRESOURCES } from '../../graphql';
// import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import {
  BarChart as RechartsBarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar
} from 'recharts';

import Http from '../../utils/http';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const Chart = ({ pageId, chart_id, check }) => {
  const [skeleton, setSkeleton] = useState();
  // const { data } = useQuery(CHART_BOTTLENECKRESOURCES);
  const dynamicApiForAllTablev1 = async (page_id) => {
    try {
      const response = await Http.post('/fetch-all-page-data', {
        a_id: '',
        pg_id: `${page_id}`,
        field_values: {},
        action: {},
        sel_table: {}
      });
      if (!response.data?.error) {
        setSkeleton(response?.data?.data);
      } else {
        toast.error(response.data?.error);
      }
    } catch (e) {
      toast.error(e?.message);
    }
  };

  useEffect(() => {
    if (check?.['allTablesData']?.length >= 1) {
      let id = chart_id?.[0]?.['pageContent']?.[0]?.['tbl_id'];
      const filtered_Data = check?.['allTablesData']?.filter((i) => i?.[id]);
      setSkeleton(filtered_Data?.[0]?.[id]);
    } else {
      dynamicApiForAllTablev1(pageId);
    }
  }, [pageId, check]);

  const colors = ['#FF5733', '#3366FF', '#33FF57', '#FF33A1'];

  const chart_data = useMemo(() => {
    let id = chart_id?.[0]?.['pageContent']?.[0]?.['tbl_id'];
    let filterd_data = skeleton?.['allTablesData']?.filter((items) => items?.[id]);
    return filterd_data?.[0]?.[id];
  }, [skeleton, check]);

  return (
    <>
      {chart_data ? (
        <Box
          sx={{
            width: '1600px',
            height: 'auto'
          }}
        >
          <Typography>{skeleton?.['chart']?.[0]?.['c_plo_loc_res_b']?.chrt_nm}</Typography>
          <RechartsBarChart width={730} height={300} data={chart_data ? chart_data : []}>
            <CartesianGrid strokeDasharray="3 3" />
            {skeleton?.['chart']?.[0]?.['c_plo_loc_res_b']?.chartDetail
              ?.filter((item) => item.axis === 'x')
              .map((obj) => (
                <XAxis key={obj.fld_id} dataKey={obj.fld_id} />
              ))}
            <YAxis />
            <Tooltip />
            <Legend />
            {skeleton?.['chart']?.[0]?.['c_plo_loc_res_b']?.chartDetail
              ?.filter((item) => item.axis === 'y')
              .map((obj, index) => (
                <Bar key={obj.fld_id} dataKey={obj.fld_id} fill={colors[index]} />
              ))}
          </RechartsBarChart>
        </Box>
      ) : (
        <Box textAlign={'center'}>{'no chart'}</Box>
      )}
    </>
  );
};

Chart.propTypes = {
  pageId: PropTypes.any,
  chart_id: PropTypes.array,
  check: PropTypes.any
};

export default Chart;
