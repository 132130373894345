// top level imports
import { useState, useContext } from 'react';
import PropTypes from 'prop-types';

// MUI
import { Tabs, Tab, Avatar, Menu, Stack, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

// custom components
import DesktopNavItems from './DesktopNavItems';
import { Link } from 'react-router-dom';

// Context-API
import { BreadcrumbContext } from '../../context';

const DesktopNav = ({ menuItems }) => {
  // context state
  const { setBreadCrumbs } = useContext(BreadcrumbContext);

  const [value, setValue] = useState(0);
  const [openMenuItems, setOpenMenuItems] = useState({ currentTarget: null, prnt_mnu: null });
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);

  // on menu btn click
  const handleOnMenuClick = (e, prnt_mnu_id) => {
    setOpenMenuItems({ currentTarget: e.currentTarget, prnt_mnu: prnt_mnu_id });
  };

  // clost menu
  const handleCloseMenuItems = () => {
    setOpenMenuItems({ currentTarget: null, prnt_mnu: null });
  };

  const handleGetMenuItem = (selectedMenuItem) => {
    if (selectedMenuItem?.prnt_mnu_id) {
      setBreadCrumbs((prev) => [
        ...prev,
        { mnu_id: selectedMenuItem?.mnu_id, mnu_nm: selectedMenuItem?.mnu_nm }
      ]);
    } else {
      setBreadCrumbs(() => [
        { mnu_id: selectedMenuItem?.mnu_id, mnu_nm: selectedMenuItem?.mnu_nm }
      ]);
    }
  };

  const onTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCurrentMenuClick = (menu) => {
    setBreadCrumbs(() => [{ mnu_id: menu?.mnu_id, mnu_nm: menu?.mnu_nm }]);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={onTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable menu items"
        textColor="inherit"
      >
        {menuItems.map((menu) => {
          return menu.children.length ? (
            <Tab
              key={menu?.mnu_id}
              label={
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {menu?.m_icn ? (
                    <Avatar alt={menu.mnu_nm} src={menu?.m_icn} sx={{ width: 24, height: 24 }} />
                  ) : null}
                  <Typography variant="body2">{menu.mnu_nm}</Typography>
                  <KeyboardArrowDown fontSize="small" />
                </Stack>
              }
              id={`navbar-tab-${menu?.mnu_id}`}
              aria-controls={`navbar-tabpanel-${menu?.mnu_id}`}
              aria-haspopup="true"
              aria-expanded="true"
              onClick={(e) => {
                handleOnMenuClick(e, menu.prnt_mnu_id);
                handleGetMenuItem(menu);
                setSelectedMenuItem(menu);
              }}
            />
          ) : (
            <Tab
              key={menu.mnu_id}
              label={menu.mnu_nm}
              icon={
                menu?.m_icn ? (
                  <Avatar
                    alt={menu.mnu_nm}
                    src={menu?.m_icn}
                    sx={{ width: 24, height: 24, bgcolor: 'inherit' }}
                  />
                ) : null
              }
              iconPosition="start"
              id={`navbar-tab-${menu?.mnu_id}`}
              aria-controls={`navbar-tabpanel-${menu?.mnu_id}`}
              component={Link}
              preventScrollReset={true}
              to={menu?.slug}
              onClick={() => {
                handleCurrentMenuClick(menu);
              }}
            />
          );
        })}
      </Tabs>
      <Menu
        id="navbar"
        anchorEl={openMenuItems.currentTarget}
        open={
          Boolean(openMenuItems.currentTarget) &&
          openMenuItems.prnt_mnu === selectedMenuItem?.prnt_mnu_id
        }
        onClose={handleCloseMenuItems}
        keepMounted
        MenuListProps={{
          'aria-labelledby': selectedMenuItem?.mnu_id
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: selectedMenuItem?.prnt_mnu_id ? 'left' : 'center'
        }}
        anchorOrigin={{
          vertical: selectedMenuItem?.prnt_mnu_id ? 'top' : 'bottom',
          horizontal: selectedMenuItem?.prnt_mnu_id ? 'right' : 'center'
        }}
      >
        {selectedMenuItem?.children.map((item) => (
          <DesktopNavItems
            key={item.mnu_id}
            menuItem={item}
            closeAll={() => {
              handleCloseMenuItems();
            }}
            hasChildMenuListIcon={selectedMenuItem?.hasChildMenuListIcon}
          />
        ))}
      </Menu>
    </>
  );
};

DesktopNav.propTypes = {
  menuItems: PropTypes.array
};

export default DesktopNav;
