import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ToGetCcaMonthDropDownValue } from '../../../features/counter/counterSlice';
import { ToGetCcaRoleDropDownValue } from '../../../features/counter/counterSlice';
// import { ToGetDefaultRoleNMonthValue } from '../../../features/counter/counterSlice';

export default function SingleSelectDropdown({ fld_id, fld_l, apidata, content }) {
  const dispatch = useDispatch();

  const [age, setAge] = React.useState(String(content?.def_val));
  const filteredArray = apidata?.fields?.filter((item) => item?.fld_id === `${fld_id}`);
  const DropValues = filteredArray?.[0]?.dropdown?.map((item) => item.display_name);
  const handleChange = (event, fld_l_Values) => {
    if (fld_l_Values === 'Month') {
      dispatch(ToGetCcaMonthDropDownValue(event.target.value));
      setAge(event.target.value);
    } else if (fld_l_Values === 'Role') {
      dispatch(ToGetCcaRoleDropDownValue(event.target.value));
      setAge(event.target.value);
    }
  };

  // const BasicSelectedValues = (values) => {
  //   if (values === 'Role') {
  //     return 'FLM';
  //   } else if (values === 'Month') {
  //     return '1';
  //   }
  // };

  // const BasicSelectedValues = useCallback(
  //   (values) => {
  //     if (values === 'Role' && apidata !== undefined) {
  //       console.log('values...', values, apidata);
  //       const string = apidata?.cca_role.toString();
  //       dispatch(ToGetCcaMonthDropDownValue(String(content?.def_val)));
  //       return string;
  //     } else if (values === 'Month' && apidata !== undefined) {
  //       console.log('values1...', values, apidata);
  //       const string = apidata?.cca_month_def.toString();
  //       dispatch(ToGetCcaRoleDropDownValue(String(content?.def_val)));
  //       return string;
  //     }
  //   },
  //   [apidata]
  // );

  // useEffect(() => {
  //   if (apidata) {
  //     console.log('apidata...', apidata);
  //     dispatch(ToGetDefaultRoleNMonthValue(apidata));
  //   }
  // }, [apidata]);

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: '3px',
        width: 'fit-content'
      }}
    >
      <Stack direction="row" spacing={1}>
        <Typography>{fld_l}</Typography>
      </Stack>

      <FormControl sx={{ width: 100 }} size="small">
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={age}
          displayEmpty
          onChange={(event) => handleChange(event, fld_l)}
        >
          {DropValues?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
SingleSelectDropdown.propTypes = {
  fld_id: PropTypes.string,
  fld_l: PropTypes.string,
  layout: PropTypes.object,
  apidata: PropTypes.object,
  DefaltValueDropdown: PropTypes.array,
  content: PropTypes.object
};
