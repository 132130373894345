// authService.js

import { UserManager } from 'oidc-client';
import queryString from 'query-string';

class AuthService {
  constructor() {
    this.userManager = new UserManager({
      authority: 'https://mankindpharma-sandbox.onelogin.com/oidc/2/auth',
      client_id: '2ae33e80-beac-013c-5143-56e72f973540186786',
      redirect_uri: 'http://localhost:3002/auth'
      // Other configuration options...
    });
  }

  async login() {
    // await this.userManager.signinRedirect();
    const authority = 'https://mankindpharma-sandbox.onelogin.com/oidc/2';
    const client_id = '2ae33e80-beac-013c-5143-56e72f973540186786';
    const redirect_uri = 'https://xpertpace-demo.estonetech.in/app/a2/kpi-dashboard';
    const response_type = 'id_token token';

    const scope = 'openid profile';
    const params = queryString.stringify({
      client_id,
      redirect_uri,
      response_type,
      scope,
      nonce: null
    });
    const authUrl = `${authority}/auth?${params}`;
    console.log(authUrl);

    // Attempt login by navigating to authUrl
    window.location.assign(authUrl);
  }

  async completeLogin() {
    await this.userManager.signinRedirectCallback();
  }

  async logout() {
    await this.userManager.signoutRedirect();
  }

  // Other methods as needed...
}

export default new AuthService();
