import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from 'recharts';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const StackedBarChart = ({ layout, apidata, content }) => {
  const chart_data = useMemo(() => {
    if (content) {
      const ChartId = content?.chrt_id;
      const api_data = apidata?.chart;
      let filterd_data = api_data?.filter((items) => items?.[ChartId]);
      return filterd_data?.[0]?.[ChartId];
    }
  }, [apidata]);
  const colors = ['#f666ab', '#bcbad4', '#FF5733', '#3366FF', '#33FF57', '#FF33A1'];

  let q = [];
  for (const i of chart_data?.chartTableData || []) {
    let obj = {};
    for (const j of content?.chartDetail || []) {
      if (j.axis === 'x') {
        obj[j.ttl] = i[j.fld_id];
      } else {
        obj[j.ttl] = i[j.fld_id];
      }
    }
    q.push(obj);
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload) {
      const [barData] = payload;
      return (
        <>
          <Card sx={{ maxWidth: 300 }}>
            <CardContent>
              {Object.keys(barData.payload).map((key) => (
                <Typography key={key} noWra fontWeight="bold">
                  {key}:{' '}
                  {key === 'Frequency Compliance'
                    ? barData.payload[key] + '%'
                    : barData.payload[key]}
                </Typography>
              ))}
            </CardContent>
          </Card>
        </>
      );
    }

    return null;
  };

  CustomTooltip.propTypes = {
    active: PropTypes.any,
    payload: PropTypes.any,
    value: PropTypes.any
  };

  // console.log('q1...', q, content, chart_data);
  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          width={500}
          height={300}
          data={chart_data?.chartTableData ? q : []}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          {layout?.pageContent?.[0]?.chartDetail
            ?.filter((item) => item.axis === 'x')
            .map((obj) => (
              <XAxis key={obj.ttl} dataKey={obj.ttl} />
            ))}
          <YAxis hide />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {layout?.pageContent?.[0]?.chartDetail
            ?.filter((item) => item.axis === 'y')
            .map((obj, index) => (
              <Bar
                key={obj.fld_id}
                dataKey={obj.ttl}
                barSize={200}
                fill={colors[index]}
                stackId="a"
              >
                <LabelList dataKey={obj.ttl} position="top" />
                {/* <LabelList dataKey={obj.ttl} position="middle" /> */}
              </Bar>
            ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default StackedBarChart;

StackedBarChart.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object
};
