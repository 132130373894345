import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts';

const Data1 = [
  {
    act_doc_ct: '342',
    'Speciality Doctor Wise Count': 'GP'
  },
  {
    act_doc_ct: '126',
    'Speciality Doctor Wise Count': 'PHYSICIAN'
  },
  {
    act_doc_ct: '120',
    'Speciality Doctor Wise Count': 'PEDIATRICIAN'
  },
  {
    act_doc_ct: '96',
    'Speciality Doctor Wise Count': 'GYNAECOLOGIST'
  },
  {
    act_doc_ct: '84',
    'Speciality Doctor Wise Count': 'DENTIST'
  },
  {
    act_doc_ct: '60',
    'Speciality Doctor Wise Count': 'DIABETIC SPECIALIST'
  },
  {
    act_doc_ct: '54',
    'Speciality Doctor Wise Count': 'ORTHOPEDIC'
  },
  {
    act_doc_ct: '30',
    'Speciality Doctor Wise Count': 'ENT SURGEON'
  },
  {
    act_doc_ct: '30',
    'Speciality Doctor Wise Count': 'MBBS GP'
  },
  {
    act_doc_ct: '18',
    'Speciality Doctor Wise Count': 'SURGEON'
  },
  {
    act_doc_ct: '18',
    'Speciality Doctor Wise Count': 'GYNEC-IVF'
  },
  {
    act_doc_ct: '16',
    'Speciality Doctor Wise Count': 'DERMATOLOGIST'
  },
  {
    act_doc_ct: '12',
    'Speciality Doctor Wise Count': 'CARDIOLOGIST'
  },
  {
    act_doc_ct: '12',
    'Speciality Doctor Wise Count': 'GP-GYNE'
  },
  {
    act_doc_ct: '12',
    'Speciality Doctor Wise Count': 'ENT'
  }
];

export default function TestingHorizontalBar() {
  return (
    <BarChart
      width={500}
      height={400}
      data={Data1}
      layout="vertical"
      margin={{
        top: 20,
        left: 20,
        right: 20,
        bottom: 10
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis hide type="number" domain={[0, 'dataMax + 342']} />
      <YAxis type="category" dataKey={'Speciality Doctor Wise Count'} interval={0} />
      <Tooltip shared={false} trigger="click" />
      <Legend />
      <Bar dataKey="act_doc_ct" fill="#8884d8" barSize={30}>
        <LabelList
          dataKey="act_doc_ct"
          position="right"
          fill="#00000"
          fontSize={'0.75rem'}
          formatter={(value) => (value !== 0 ? value : null)}
        />
      </Bar>
    </BarChart>
  );
}
