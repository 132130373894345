import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NestedTable from './NestedTable';
const ParentTable = ({ item, apidata }) => {
  // item is layout object here....
  const [tableData, setTableData] = useState([]);
  const [tablename, setTableName] = useState('');
  // const ShowDefaultTable = item?.pageContent?.[0]?.got?.filter((column) => column.is_def === 1);

  useEffect(() => {
    if (tablename) {
      const filterdColumn = item?.pageContent?.[0]?.got?.filter(
        (column) => column.own_table === tablename
      );
      setTableData(filterdColumn);
    } else {
      const ShowDefaultTable = item?.pageContent?.[0]?.got?.filter((column) => column.is_def === 1);
      setTableData(ShowDefaultTable);
    }
  }, [tablename]);

  return (
    <>
      <NestedTable
        ShowDefaultTable={tableData}
        setTableName={setTableName}
        tablename={tablename}
        apidata={apidata}
        content={tableData}
        // chart_data={chart_data}
      />
    </>
  );
};

export default ParentTable;
ParentTable.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object,
  item: PropTypes.object,
  cell: PropTypes.shape({
    column: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    getValue: PropTypes.func.isRequired
    // Add other expected properties with appropriate types
  }).isRequired
};
