// // import { stringify } from 'query-string';
// import queryString from 'query-string';

// // Static oidc params for a single provider
// const authority = 'https://mankindpharma-sandbox.onelogin.com/oidc/2/auth';
// const client_id = '2ae33e80-beac-013c-5143-56e72f973540186786';
// // const redirect_uri = 'https://xpertpace-demo.estonetech.in/app/a2/kpi-dashboard';
// const redirect_uri = "http://localhost:3000/auth";
// const response_type = 'id_token token';
// const scope = 'openid profile';

// const TestingOidc = ({ state, nonce }) => {
//   // Generate authentication URL
//   const params = {
//     client_id,
//     redirect_uri,
//     response_type,
//     scope,
//     state,
//     nonce
//   };
//   const queryStringResult = queryString.stringify(params);
//   const authUrl = `${authority}/auth?${queryStringResult}`;
//   console.log(authUrl);

//   // Attempt login by navigating to authUrl
//   window.location.assign(authUrl);
// };

// export default TestingOidc;

// import { UserManager } from 'oidc-client';
import React from 'react';
import AuthService from '../AuthService/AuthService';

const handleLogin = async () => {
  await AuthService.login();
};

const TestingOidc = () => {
  return (
    <>
      <div>
        <h1>Login Page</h1>
        <button onClick={handleLogin}>Login</button>
      </div>
    </>
  );
};

export default TestingOidc;
