import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PropTypes } from 'prop-types';
import Dialog from '@mui/material/Dialog';
// import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import Http from '../../utils/http';
import Field from '../Field/Field';
import BasicDateTimePicker from '../DateTime/DateAndTime';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TableCoulumnHyperLink = ({ open, setOpen, layout, celldata }) => {
  const [hyperpagedata, setHyperPageData] = useState(null);

  const fetchHyperPageData = async (pg_id) => {
    try {
      const response = await Http.post('/get-page-content-data-wrt-ids', { pg_id });
      if (!response.data?.error) {
        setHyperPageData(response.data?.data[0].pageContent);
      } else {
        toast.error(response.data?.error);
      }
    } catch (e) {
      toast.error(e?.message);
    }
  };

  useEffect(() => {
    if (celldata?.hpr_pg_id) {
      fetchHyperPageData(celldata?.hpr_pg_id);
    }
  }, [celldata]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Accordion 1</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {
                <Grid container spacing={2} alignItems="flex-start">
                  {hyperpagedata?.map((content, pos) => (
                    <Grid item xs="auto" key={`${layout?.sctn_id}-${pos}`}>
                      {content?.cntnt_ty === 'field' ? (
                        content?.dty !== 'datetime' ? (
                          <Field
                            field={content}
                            key={`${layout?.sctn_id}-${content?.fld_id}`}
                            id={`${layout?.sctn_id}-${content?.fld_id}`}
                            // label={celldata?.row[`${content?.fld_id}`]}
                            defaultValue={celldata?.row[`${content?.fld_id}`]}
                          />
                        ) : (
                          <BasicDateTimePicker />
                        )
                      ) : (
                        content?.cntnt_ty
                      )}
                    </Grid>
                  ))}
                </Grid>
              }
            </AccordionDetails>
          </Accordion>
          <Divider />
        </List>
      </Dialog>
    </div>
  );
};
TableCoulumnHyperLink.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  layout: PropTypes.object,
  celldata: PropTypes.object
};

export default TableCoulumnHyperLink;
