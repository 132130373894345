import PropTypes from 'prop-types';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import ComposedCharts from '../../components/ComposedChart/ComposedChart';
// import NestedTabs from '../../components/NestedTabs/NestedTabs';
import MuiTabs from '../../components/MuiTabs/MuiTabs';

// mui function
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
//  mui function end
// *********************************************

const TabLayout = ({ childLayout, layout, apidata }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/*put b1 in below line*/}
      {childLayout?.map((item, index) =>
        item?.pageContent?.[0]?.['tmplt_id'] === 'b11' ? (
          <Box key={index} sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              {/* <Typography>hlo</Typography> */}
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {childLayout?.map((item, index) => (
                  <Tab
                    key={item?.sctn_id}
                    label={item?.pageContent?.[0]?.['ttl'] || ''}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
        ) : null
      )}

      {/*a2_m1 s1c2 */}
      {/* {childLayout?.[0]?.pageContent?.[0]?.['cntnt_ty'] === 'section' ? (
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              '& .MuiTabs-flexContainer': {
                display: 'flex',
                placeContent: 'center'
              },
              '& .MuiTabs-indicator': {
                display: 'none'
              },
              '& .Mui-selected': {
                color: 'white !important',
                backgroundColor: '#1976d2',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
              },
              '& .MuiTab-root': {
                color: 'white'
              }
            }}
          >
            {childLayout?.map((item, index) => (
              <Tab
                sx={{
                  color: 'white',
                  backgroundColor: '#bdbdbd',
                  marginRight: '4px',
                  borderRadius: '30px',
                  transition: 'box-shadow 0.3s ease',
                  '&:hover': {
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.7)'
                  }
                }}
                key={item?.sctn_id}
                label={item?.lbl || 'ChartHeading'}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
      ) : null} */}

      {/* actual content */}
      {/* <Box sx={{ width: '100%' }}>
        {childLayout?.map((item, index) =>
          item?.pageContent?.[0]?.['tmplt_id'] === 'b1' ? (
            <CustomTabPanel key={index} value={value} index={0}>
              <ComposedCharts
                childLayout={childLayout}
                layout={layout}
                chrt_id={item?.pageContent?.[0]?.['chrt_id']}
                apidata={apidata}
              />
            </CustomTabPanel>
          ) : item?.pageContent?.[0]?.['ttl'] === 'Compliance' ? (
            <NestedTabs
              childLayout={childLayout}
              layout={layout}
              chrt_id={item?.pageContent?.[0]?.['chrt_id']}
              apidata={apidata}
            />
          ) : null
        )}
      </Box> */}

      <MuiTabs childLayout={childLayout} layout={layout} apidata={apidata} />
    </>
  );
};

TabLayout.propTypes = {
  childLayout: PropTypes.array,
  layout: PropTypes.object,
  apidata: PropTypes.object
};

export default TabLayout;
