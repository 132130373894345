import { Outlet } from 'react-router-dom';
import AppLayout from '../../layouts/AppLayout/AppLayout';
import withAuth from '../../guards/withAuth';
import { BreadcrumbContext, PageIdContext } from '../../context';
import { useState } from 'react';

const AppDetails = () => {
  const [pageId, setPageId] = useState('');
  const [breadCrumbs, setBreadCrumbs] = useState([]);

  return (
    <PageIdContext.Provider value={{ pageId, setPageId }}>
      <BreadcrumbContext.Provider value={{ breadCrumbs, setBreadCrumbs }}>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </BreadcrumbContext.Provider>
    </PageIdContext.Provider>
  );
};

export default withAuth(AppDetails);
