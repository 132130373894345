import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const HeadersTiel = React.memo(({ layout, apidata, content }) => {
  const chart_data = useMemo(() => {
    if (content) {
      const table_id = content?.chrt_id;
      const api_data = apidata?.chart;
      let filterd_data = api_data?.filter((items) => items?.[table_id]);
      return filterd_data?.[0]?.[table_id];
    }
  }, [apidata, layout?.sctn_id]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '150px',
          p: 2,
          backgroundColor: content?.bg_color || '#e8eef7',
          borderRadius: '35px 35px 0 0'
        }}
      >
        <Stack spacing={7}>
          <Box
            sx={{
              // border: '1px dashed grey',
              color: '#3B459F',
              fontWeight: 'bold',
              fontFamily: '"Tableau Bold", Tableau, Arial, sans-serif',
              fontSize: '25px',
              textAlign: 'center'
            }}
          >
            {chart_data?.chartParams?.[0]?.chartParamsValue?.[0]?.prm_val}
          </Box>
          <Box
            sx={{
              // border: '1px dashed grey',
              color: '#3B459F',
              fontWeight: 'bold',
              fontFamily: '"Tableau Bold", Tableau, Arial, sans-serif',
              fontSize: '21px'
            }}
          >
            {chart_data?.chartParams?.[1]?.chartParamsValue?.[0]?.prm_val}
            {/* hello world */}
          </Box>
        </Stack>
      </Box>
    </>
  );
});

HeadersTiel.displayName = 'HeadersTiel';
export default HeadersTiel;
HeadersTiel.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
