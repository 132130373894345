import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FaFilterCircleXmark } from 'react-icons/fa6';
import BasicMenu from '../MuiMenu/Menu';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200
    }
  }
};

const names = [
  'All',
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder'
];

export default function MultipleSelectCheckmarks() {
  const [personName, setPersonName] = React.useState([]);

  console.log('personName...', personName);

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <>
      <Box sx={{ border: '1px dashed black', width: 'fit-Content', backgroundColor: '#f5f5f5' }}>
        <Stack direction="row" spacing={1}>
          <Typography>Month</Typography>
          <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
            <Box>
              <FaFilterCircleXmark />
            </Box>
            <BasicMenu />
          </Stack>
        </Stack>

        <FormControl sx={{ width: 100 }} size="small">
          <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {names.map((name) => (
              <MenuItem key={name} value={name} sx={{ padding: '0' }}>
                <Checkbox checked={personName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}

            <Stack direction="row" spacing={2} justifyContent="center">
              <Button variant="outlined">Cancel</Button>
              <Button variant="outlined">Apply</Button>
            </Stack>
          </Select>
        </FormControl>
      </Box>
    </>
  );
}
