// src\components\AllTypeChart\LineChart.jsx
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer
} from 'recharts';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { useMemo } from 'react';

export default function LineChartComponent({ chrt_id, apidata, item }) {
  const chart_data = useMemo(() => {
    if (chrt_id) {
      let filterd_data = apidata?.chart?.filter((items) => items?.[chrt_id]);
      return filterd_data?.[0]?.[chrt_id];
    }
  }, [apidata]);

  let q = [];
  for (const i of chart_data?.chartTableData || []) {
    let obj = {};
    for (const j of item?.pageContent?.[0]?.chartDetail || []) {
      if (j.axis === 'x') {
        obj[j.fld_id] = i[j.fld_id];
      } else {
        obj[j?.ttl] = i[j.fld_id];
      }
    }
    q.push(obj);
  }

  // console.log('custome...', q, chart_data);

  const maxValuedline = useMemo(() => {
    if (q.length) {
      const filterObject = item?.pageContent?.[0]?.chartDetail
        .filter((item) => item.axis === 'y' && item.kf_ty === 'line')
        .map((data) => data.ttl);
      const properties = filterObject; // Array of dynamic property names

      const maxValue = q.reduce((max, d) => {
        // Map the dynamic properties to their values for the current object 'd'
        const values = properties.map((prop) => d[prop]);
        // Find the maximum value among the properties for the current object
        const value = Math.max(...values);
        // Update the overall maximum value
        return value > max ? value : max;
      }, -Infinity);

      const increasedMaxValue = Math.floor(maxValue * 1.1);

      // console.log('increasedMaxValueline...', filterObject, increasedMaxValue);
      return increasedMaxValue;
    }
  }, [q]);

  const CustomizedLabel = ({ x, y, value }) => (
    <>
      <text
        x={x}
        y={y}
        dy={15}
        fill="black"
        style={{ fontSize: '12px', fontWeight: 'bold' }}
        textAnchor="middle"
      >
        {`${value}%`}
      </text>
    </>
  );

  CustomizedLabel.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    value: PropTypes.any
  };

  const CustomizedLabelNotpercent = ({ x, y, value }) => (
    <>
      <text x={x} y={y} dy={-10} fill="black" style={{ fontSize: '12px', fontWeight: 'bold' }}>
        {`${value}`}
      </text>
    </>
  );

  CustomizedLabelNotpercent.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    value: PropTypes.any
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload) {
      const [barData] = payload;
      return (
        <>
          <Card sx={{ maxWidth: 300 }}>
            <CardContent>
              {Object.keys(barData.payload).map((key) => (
                <Typography key={key} noWra fontWeight="bold">
                  {key}:{' '}
                  {key === 'Frequency Compliance'
                    ? barData.payload[key] + '%'
                    : barData.payload[key]}
                </Typography>
              ))}
            </CardContent>
          </Card>
        </>
      );
    }
    return null;
  };

  CustomTooltip.propTypes = {
    active: PropTypes.any,
    payload: PropTypes.any,
    value: PropTypes.any
  };

  const colors = [
    '#FF5733', // Red-Orange
    '#17becf',
    '#ff0000',
    '#f666ab',
    '#33FF57', // Green
    '#fc898a', //light pink
    '#FF33A1', // Pink
    '#3357FF', // Blue
    '#FFC300', // Yellow
    '#DAF7A6', // Light Green
    '#900C3F', // Dark Red
    '#581845', // Purple
    '#C70039', // Red
    '#FFC0CB', // Light Pink
    '#008080', // Teal
    '#FFD700' // Gold
  ];
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        width={730}
        height={250}
        data={chart_data?.chartTableData ? q : []}
        margin={{ top: 10, right: 30, left: 30, bottom: 5 }}
      >
        {item?.pageContent?.[0]?.chartDetail
          ?.filter((item) => item.axis === 'x')
          .map((obj) => (
            <XAxis
              key={obj.fld_id}
              dataKey={obj.fld_id}
              tick={{ fontWeight: 'bold' }}
              padding={{ left: 10, right: 10 }} // Add padding to the left and right
            />
          ))}
        <YAxis domain={[0, maxValuedline]} hide />
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" align="center" layout="horizontal" height={36} />
        {item?.pageContent?.[0]?.chartDetail
          ?.filter((item) => item.axis === 'y')
          .map((obj, index) =>
            obj.kf_ty === 'line' ? (
              <Line
                key={obj.fld_id}
                type="linear"
                barSize={5}
                strokeWidth={3}
                dataKey={obj.ttl}
                stroke={colors[index]}
                legendType="square"
              >
                <LabelList
                  dataKey={obj.ttl}
                  position="top"
                  content={
                    obj.is_percent_value == !null ? (
                      <CustomizedLabel />
                    ) : (
                      <CustomizedLabelNotpercent />
                    )
                  }
                />
              </Line>
            ) : null
          )}
      </LineChart>
    </ResponsiveContainer>
  );
}

LineChartComponent.propTypes = {
  childLayout: PropTypes.array,
  layout: PropTypes.object,
  chrt_id: PropTypes.string,
  apidata: PropTypes.object,
  item: PropTypes.array
};
