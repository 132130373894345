// import { useEffect, useState } from 'react';
// import Http from '../utils/http';

// eslint-disable-next-line react/display-name
const withAuth = (Component) => (props) => {
  // const [allowEntry, setAllowEntry] = useState(false);

  // const accessToken = localStorage.getItem('access_token');
  // const authExpired = !accessToken || localStorage.getItem('expiry') < Date.now();

  // useEffect(() => {
  //   // if an access token doesn't exist in the local storage, or if login session has expired, redirect the user to login
  //   if (authExpired) {
  //     window.location.href = '/login';
  //   } else {
  //     Http.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
  //       'access_token'
  //     )}`;
  //     setAllowEntry(true);
  //   }
  // }, [authExpired]);

  return <Component {...props} />;
};

export default withAuth;
