// utils.js
import Http from './http';

const fetchContentData = async (Page_id_input) => {
  const role_id = localStorage.getItem('role_id');
  const EmployeID = localStorage.getItem('emp_id');
  try {
    const response = await Http.post('/get-page-content-data-wrt-ids', {
      role_id: role_id,
      emp_id: EmployeID,
      pg_id: Page_id_input
    });
    if (!response.data?.error) {
      return response.data.data;
    }
  } catch (e) {
    console.log(e?.message);
  }
};

const fetchSectionData = async (Page_id_input) => {
  const role_id = localStorage.getItem('role_id');
  const EmployeID = localStorage.getItem('emp_id');
  try {
    const response = await Http.post('/get-page-section-wrt-ids', {
      role_id: role_id,
      emp_id: EmployeID,
      pg_id: Page_id_input
    });
    if (!response.data?.error) {
      return response.data.data;
    }
  } catch (e) {
    console.log(e?.message);
  }
};

export { fetchContentData, fetchSectionData };
