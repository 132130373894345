import React, { useEffect } from 'react';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ToGetDropDownValueAmount } from '../../../features/counter/counterSlice';
import { ToGetDropDownValuemonthSingle } from '../../../features/counter/counterSlice';
import { ToGetDropDownValueTopBottomPerformers } from '../../../features/counter/counterSlice';
export default function SingleDrop({
  fld_l,
  DefaultValue,
  OptionsValue,
  updateFieldValues,
  setFilterKey,
  setFilterData
}) {
  const dispatch = useDispatch();

  const [age, setAge] = React.useState(DefaultValue.split(','));
  // const filteredArray = apidata?.filter((item) => item?.[fld_l] === `${fld_id}`);
  const DropValues = OptionsValue.split(',');

  const handleChange = (event, fld_l_Values) => {
    if (fld_l_Values === 'Month') {
      const monthMapping = {
        Jan: '01',
        Feb: '02',
        Mar: '03',
        Apr: '04',
        May: '05',
        Jun: '06',
        Jul: '07',
        Aug: '08',
        Sep: '09',
        Oct: '10',
        Nov: '11',
        Dec: '12'
      };
      const SelectedMonth = monthMapping[event.target.value];
      dispatch(ToGetDropDownValuemonthSingle(SelectedMonth));
      setAge(event.target.value);
    } else if (fld_l_Values === 'Value') {
      dispatch(ToGetDropDownValueAmount(event.target.value));
      setAge(event.target.value);
    } else if (fld_l_Values === 'Top/Bottom Performers') {
      setAge(event.target.value);
      dispatch(ToGetDropDownValueTopBottomPerformers(event.target.value));
    }
    const selected = event.target.value;
    setFilterKey((prev) => [...prev, fld_l_Values]);
    setFilterData((prev) => [...prev, ...selected]);
  };

  useEffect(() => {
    updateFieldValues(fld_l, age);
  }, [age, fld_l]);

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: '3px',
        width: 'fit-content'
      }}
    >
      <Stack direction="row" spacing={1}>
        <Typography>{fld_l.charAt(0).toUpperCase() + fld_l.slice(1)}</Typography>
      </Stack>

      <FormControl sx={{ width: 100 }} size="small">
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={age}
          displayEmpty
          onChange={(event) => handleChange(event, fld_l)}
        >
          {DropValues?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
SingleDrop.propTypes = {
  fld_id: PropTypes.string,
  fld_l: PropTypes.string,
  layout: PropTypes.object,
  apidata: PropTypes.object,
  DefaltValueDropdown: PropTypes.array,
  DefaultValue: PropTypes.string,
  OptionsValue: PropTypes.string,
  updateFieldValues: PropTypes.func,
  setFilterKey: PropTypes.func,
  setFilterData: PropTypes.func
};
