import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProgressBar from '../ProgressBar/ProgressBar';
// import Grid from '@mui/material/Unstable_Grid2';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`
//   };
// }

const NestedTabs = ({ childLayout, layout, chrt_id, apidata, setMiniTile, item }) => {
  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const chart_data = useMemo(() => {
  //   if (chrt_id) {
  //     const api_data = apidata?.chart;
  //     let filterd_data = api_data?.filter((items) => items?.[chrt_id]);
  //     return filterd_data?.[0]?.[chrt_id];
  //   }
  // }, [apidata]);

  useEffect(() => {
    if (chrt_id) {
      const api_data = apidata?.chart;
      let filterd_data = api_data?.filter((items) => items?.[chrt_id]);
      setMiniTile(filterd_data?.[0]?.[chrt_id]);
    }
  }, [apidata]);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <ProgressBar
          childLayout={childLayout}
          item={item}
          layout={layout}
          chrt_id={chrt_id}
          apidata={apidata}
        />
      </Box>
    </>
  );
};

NestedTabs.propTypes = {
  childLayout: PropTypes.array,
  layout: PropTypes.object,
  chrt_id: PropTypes.string,
  apidata: PropTypes.object,
  setMiniTile: PropTypes.func,
  item: PropTypes.object
};

export default NestedTabs;
