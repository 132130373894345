import React from 'react';
import PropTypes from 'prop-types';
import Section from '../Section/Section';
import { Grid } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//  below is horizontal tabs from mui
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function BasicTabs({
  tabsData,
  initialValues_data,
  pageId,
  check,
  setCheck,
  viewplrundata,
  SetViewPlrunData
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Typography textAlign="center" variant="h6" gutterBottom>
          {tabsData?.lbl || tabsData?.sctn_id}
        </Typography>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabsData?.children?.map((i) => (
            <Tab key={i?.sctn_id} label={i?.lbl || i?.sctn_id} />
          ))}
        </Tabs>
      </Box>

      {tabsData?.children?.map((i, j) => (
        <CustomTabPanel value={value} index={j} key={i?.sctn_id}>
          <Section
            pageId={pageId}
            data={{
              lnkd_pg_id: tabsData?.children?.[j]?.['pg_id'],
              cntnt: tabsData?.children?.[j]?.['sctn_id']
            }}
            check={check}
            setCheck={setCheck}
            viewplrundata={viewplrundata}
            SetViewPlrunData={SetViewPlrunData}
            initialValues_data={initialValues_data}
          />
        </CustomTabPanel>
      ))}
    </Box>
  );
}

BasicTabs.propTypes = {
  present: PropTypes.bool,
  tabsData: PropTypes.object,
  initialValues_data: PropTypes.array,
  summaryData: PropTypes.any,
  dynamic_page: PropTypes.any,
  content: PropTypes.any,
  pageId: PropTypes.any,
  check: PropTypes.any,
  setCheck: PropTypes.func,
  viewplrundata: PropTypes.any,
  SetViewPlrunData: PropTypes.func,
  gettablename: PropTypes.any
};
// mui horizontal tabs ends here.

const Dynamic = ({
  present,
  dynamic_page,
  summaryData,
  content,
  pageId,
  check,
  setCheck,
  viewplrundata,
  SetViewPlrunData,
  initialValues_data,
  gettablename
}) => {
  return (
    <>
      {present ? (
        <Grid item xs={9} key={content.cntnt_ty + content?.sctn_id} sx={{ width: '1600px' }}>
          <Section
            data={{ lnkd_pg_id: dynamic_page?.pg_id, cntnt: dynamic_page?.sctn_id }}
            initialValues_data={initialValues_data}
            key={content?.sctn_id + content.cntnt_ty + content?.sctn_id + content.cntnt_ty}
            pageId={pageId}
            check={check}
            setCheck={setCheck}
            dynamic_page={dynamic_page}
            viewplrundata={viewplrundata}
            SetViewPlrunData={SetViewPlrunData}
            gettablename={gettablename}
          />
        </Grid>
      ) : null}

      {!present && summaryData?.length ? (
        <Grid
          item
          xs={9}
          sx={{ width: '1600px' }}
          // key={`${data?.pageContent?.[0]?.pg_id}-${data?.pageContent?.[0]?.sctn_id}`}
        >
          {summaryData?.map((data) =>
            data?.dvd_in === 'T' && data?.children?.length ? (
              <BasicTabs
                key={`${data?.pg_id}-${data?.sctn_id}`}
                tabsData={data}
                initialValues_data={initialValues_data}
                pageId={pageId}
                check={check}
                setCheck={setCheck}
                viewplrundata={viewplrundata}
                SetViewPlrunData={SetViewPlrunData}
                gettablename={gettablename}
              />
            ) : (
              <Section
                data={{
                  lnkd_pg_id: data?.pg_id,
                  cntnt: data?.sctn_id
                }}
                initialValues_data={initialValues_data}
                key={`${data?.pg_id}-${data?.sctn_id}`}
                pageId={pageId}
                check={check}
                setCheck={setCheck}
                viewplrundata={viewplrundata}
                SetViewPlrunData={SetViewPlrunData}
                gettablename={gettablename}
              />
            )
          )}
        </Grid>
      ) : null}
    </>
  );
};

Dynamic.propTypes = {
  present: PropTypes.bool,
  initialValues_data: PropTypes.array,
  summaryData: PropTypes.any,
  dynamic_page: PropTypes.any,
  content: PropTypes.any,
  pageId: PropTypes.any,
  check: PropTypes.any,
  setCheck: PropTypes.func,
  viewplrundata: PropTypes.any,
  SetViewPlrunData: PropTypes.func,
  gettablename: PropTypes.any
};

export default Dynamic;
