// top level imports
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// MUI
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  Avatar,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

// react-router-dom
import { useNavigate } from 'react-router-dom';

const MobileNavItems = ({ menuItem, hasChildMenuListIcon, closeDrawer }) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  return menuItem.children.length ? (
    <>
      <ListItem key={menuItem.mnu_id} sx={{ px: 'inherit' }}>
        <ListItemButton onClick={() => setOpen((prev) => !prev)} sx={{ py: 0 }}>
          {hasChildMenuListIcon ? (
            <ListItemIcon>
              <Avatar
                alt={menuItem.mnu_nm}
                src={menuItem?.m_icn}
                sx={{ width: 24, height: 24, visibility: menuItem?.m_icn ? 'inherit' : 'hidden' }}
              />
            </ListItemIcon>
          ) : null}
          <ListItemText primary={menuItem.mnu_nm} />
          {menuItem.children.length ? open ? <ExpandLess /> : <ExpandMore /> : null}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} unmountOnExit>
        <List sx={{ py: 0 }}>
          {menuItem.children.map((item) => (
            <MobileNavItems
              key={item.mnu_id}
              menuItem={item}
              hasChildMenuListIcon={menuItem?.hasChildMenuListIcon}
              closeDrawer={closeDrawer}
            />
          ))}
        </List>
      </Collapse>
    </>
  ) : (
    <ListItem
      onClick={() => {
        setOpen(false);
        closeDrawer();
        navigate(`${menuItem.slug}`);
      }}
    >
      {/* if a menu item is at root level, it should be prominent (or bold), otherwise it should be small in size */}
      <ListItemIcon>
        {hasChildMenuListIcon ? (
          <Avatar
            alt={menuItem.mnu_nm}
            src={menuItem?.m_icn}
            sx={{ width: 24, height: 24, visibility: menuItem?.m_icn ? 'inherit' : 'hidden' }}
          />
        ) : null}
      </ListItemIcon>
      <ListItemText
        primary={menuItem?.prnt_mnu_id ? null : menuItem.mnu_nm}
        secondary={menuItem?.prnt_mnu_id ? menuItem.mnu_nm : null}
      />
    </ListItem>
  );
};

MobileNavItems.propTypes = {
  menuItem: PropTypes.object,
  hasChildMenuListIcon: PropTypes.bool,
  closeDrawer: PropTypes.func
};

export default MobileNavItems;
