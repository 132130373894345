// top level imports
import PropTypes from 'prop-types';
import { useState, useContext } from 'react';

// MUI
import {
  Button,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Typography,
  Avatar,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';

// react-router-dom
import { Link } from 'react-router-dom';

// Context-API
import { BreadcrumbContext } from '../../context';

const DesktopNavItems = ({ menuItem, isRoot, closeAll, hasChildMenuListIcon }) => {
  const [openMenuItems, setOpenMenuItems] = useState({ currentTarget: null, prnt_mnu: null });

  // context state
  const { setBreadCrumbs } = useContext(BreadcrumbContext);

  // on menu btn click
  const handleOnMenuClick = (e, prnt_mnu_id) => {
    setOpenMenuItems({ currentTarget: e.currentTarget, prnt_mnu: prnt_mnu_id });
  };

  // clost menu
  const handleCloseMenuItems = () => {
    setOpenMenuItems({ currentTarget: null, prnt_mnu: null });
  };

  const handleGetMenuItem = (selectedMenuItem) => {
    if (selectedMenuItem?.prnt_mnu_id) {
      setBreadCrumbs((prev) => [
        ...prev,
        { mnu_id: selectedMenuItem?.mnu_id, mnu_nm: selectedMenuItem?.mnu_nm }
      ]);
    } else {
      setBreadCrumbs(() => [
        { mnu_id: selectedMenuItem?.mnu_id, mnu_nm: selectedMenuItem?.mnu_nm }
      ]);
    }
  };

  return menuItem.children.length ? (
    <>
      {isRoot ? (
        <Tab
          key={menuItem?.mnu_id}
          label={
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
              {menuItem?.m_icn ? (
                <Avatar
                  alt={menuItem.mnu_nm}
                  src={menuItem?.m_icn}
                  sx={{ width: 24, height: 24 }}
                />
              ) : null}
              <Typography variant="body2">{menuItem.mnu_nm}</Typography>
              <KeyboardArrowDown fontSize="small" />
            </Stack>
          }
          id={`navbar-tab-${menuItem?.mnu_id}`}
          aria-controls={`navbar-tabpanel-${menuItem?.mnu_id}`}
          aria-haspopup="true"
          aria-expanded="true"
          onClick={(e) => {
            handleOnMenuClick(e, menuItem.prnt_mnu_id);
            handleGetMenuItem(menuItem);
          }}
        />
      ) : (
        <Button
          onClick={(e) => {
            handleOnMenuClick(e, menuItem.prnt_mnu_id);
            handleGetMenuItem(menuItem);
          }}
          endIcon={menuItem.prnt_mnu_id ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
          id={menuItem.mnu_id}
          aria-controls={
            openMenuItems.currentTarget && openMenuItems.prnt_mnu === menuItem.prnt_mnu_id
              ? 'navbar'
              : undefined
          }
          aria-haspopup="true"
          aria-expanded={
            openMenuItems.currentTarget && openMenuItems.prnt_mnu === menuItem.prnt_mnu_id
              ? 'true'
              : undefined
          }
          sx={{ color: 'inherit', textTransform: 'none', px: 2 }}
          size="small"
        >
          {menuItem.mnu_nm}
        </Button>
      )}
      <Menu
        id="navbar"
        anchorEl={openMenuItems.currentTarget}
        open={
          Boolean(openMenuItems.currentTarget) && openMenuItems.prnt_mnu === menuItem.prnt_mnu_id
        }
        onClose={handleCloseMenuItems}
        keepMounted
        MenuListProps={{
          'aria-labelledby': menuItem.mnu_id
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: menuItem.prnt_mnu_id ? 'left' : 'center'
        }}
        anchorOrigin={{
          vertical: menuItem.prnt_mnu_id ? 'top' : 'bottom',
          horizontal: menuItem.prnt_mnu_id ? 'right' : 'center'
        }}
      >
        {menuItem.children.map((item) => (
          <DesktopNavItems
            key={item.mnu_id}
            menuItem={item}
            closeAll={() => {
              handleCloseMenuItems();
              if (menuItem.prnt_mnu_id) {
                closeAll();
              }
            }}
            hasChildMenuListIcon={menuItem?.hasChildMenuListIcon}
          />
        ))}
      </Menu>
    </>
  ) : (
    <MenuItem
      onClick={() => {
        closeAll();
        handleGetMenuItem(menuItem);
      }}
      component={Link}
      preventScrollReset={true}
      to={menuItem?.slug}
    >
      {hasChildMenuListIcon ? (
        <ListItemIcon>
          <Avatar
            alt={menuItem.mnu_nm}
            src={menuItem?.m_icn || ''}
            sx={{ width: 24, height: 24, visibility: menuItem?.m_icn ? 'inherit' : 'hidden' }}
          />
        </ListItemIcon>
      ) : null}
      <ListItemText>{menuItem.mnu_nm}</ListItemText>
    </MenuItem>
  );
};

DesktopNavItems.propTypes = {
  menuItem: PropTypes.object,
  isRoot: PropTypes.bool,
  closeAll: PropTypes.func,
  hasChildMenuListIcon: PropTypes.bool
};

DesktopNavItems.defaultProps = {
  isRoot: false,
  hasChildMenuListIcon: false
};

export default DesktopNavItems;
