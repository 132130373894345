import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ToGetDropDownValue } from '../../features/counter/counterSlice';
import Box from '@mui/material/Box';

const CustomTable = ({ content, apidata }) => {
  const [selectedWeek, setSelectedWeek] = useState();
  // const dropDownValues = useSelector((state) => state.counter.getDropDownValue);
  const dispatch = useDispatch();
  // console.log('dropDownValues...', dropDownValues);
  function generateData() {
    const api_data = apidata?.allTablesData?.[0]?.[content?.tbl_id];
    let main = [];
    if (api_data) {
      let table = content?.tableData;
      for (const g in api_data) {
        let data = {};
        for (const i in table) {
          data[[table[i].fld_id]] = api_data[g][table[i].fld_id];
          data[[table[i].bg_color]] = api_data[g][table[i].bg_color];
          data[[table[i].text_color]] = api_data[g][table[i].text_color];
          data[[table[i].icon]] = api_data[g][table[i].icon];
          // data[[table[i].o_mr_name]] = api_data[g][table[i].o_mr_name];
        }
        main.push(data);
      }
    }
    return main;
  }
  // console.log('table...', generateData(), content, apidata); ************

  const handleChange = (event) => {
    dispatch(ToGetDropDownValue(event.target.value));
    setSelectedWeek(event.target.value);
  };
  useEffect(() => {
    if (selectedWeek) {
      setSelectedWeek(apidata?.selected_week);
    }
  }, [apidata]);

  // current month's week
  // function getCurrentWeekOfMonth() {
  //   const currentDate = new Date();
  //   const currentDay = currentDate.getDate();
  //   const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  //   const startOfWeek = firstDayOfMonth.getDay(); // Day of the week the month starts on (0 - 6)

  //   // Calculate which week of the month the current date falls into
  //   const weekOfMonth = Math.ceil((currentDay + startOfWeek) / 7);

  //   return weekOfMonth;
  // }

  // Get current date
  // const currentDate = new Date();

  // Get month as abbreviated name (e.g., Feb for February)
  // const monthAbbreviation = currentDate.toLocaleString('default', { month: 'short' });

  // Get year as two-digit format (e.g., 24 for 2024)
  // const yearTwoDigit = currentDate.getFullYear().toString().slice(-2);

  // const weekOfMonth = getCurrentWeekOfMonth();

  // console.log('current-week...',weekOfMonth)

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650, borderTop: '3.4px solid #2c809c' }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow sx={{ borderBottom: 'hidden' }}>
            <TableCell colSpan={3} style={{ width: '33.33%' }}>
              <span style={{ color: '#000000', fontWeight: 'bold' }} hidden>
                DATERANGE
              </span>
            </TableCell>
            <TableCell colSpan={4} sx={{ textAlign: 'center' }} style={{ width: '33.33%' }}>
              <span style={{ color: '#1d2b71', fontWeight: 'bold' }}>
                {/* 01-Feb-24 to 07-Feb-24{' '} */}
                {/* {`01-${monthAbbreviation}-${yearTwoDigit} to 
                  ${
                    selectedWeek === undefined ? 7 * weekOfMonth : selectedWeek * 7
                  }-${monthAbbreviation}-${yearTwoDigit}`} */}
                {apidata?.week_range_label}
              </span>
            </TableCell>
            <TableCell colSpan={3} sx={{ textAlign: 'end' }} style={{ width: '33.33%' }}>
              <span style={{ color: '#000000', fontWeight: 'bold' }}>
                Week :{' '}
                <select
                  name="Weeks"
                  id=""
                  value={selectedWeek}
                  onChange={handleChange}
                  style={{
                    fontSize: '14px',
                    padding: '3px',
                    width: 'min-content',
                    border: '2px solid #1d2b71',
                    borderRadius: '8px',
                    backgroundColor: '#f4f4f4',
                    transition: 'background-color 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#e0e0e0'
                    },
                    '&:focus': {
                      outline: 'none',
                      boxShadow: '0 0 0 2px #1d2b71'
                    }
                  }}
                >
                  <option value="1" selected={apidata?.selected_week === 1}>
                    Week 1
                  </option>
                  <option value="2" selected={apidata?.selected_week === 2}>
                    Week 2
                  </option>
                  <option value="3" selected={apidata?.selected_week === 3}>
                    Week 3
                  </option>
                  <option value="4" selected={apidata?.selected_week === 4}>
                    Week 4
                  </option>
                </select>
              </span>
            </TableCell>
          </TableRow>

          {/* <TableRow>
<TableCell align="right">RANK</TableCell>
<TableCell align="right">NAME</TableCell>
<TableCell align="right">HEADQUARTER </TableCell>
<TableCell align="right">Score</TableCell>
<TableCell align="right">DOCTORCOVERAGE</TableCell>
<TableCell>KYCDOCTOR
COVERAGE</TableCell>
<TableCell align="right">KYCDOCTOR
RCPA</TableCell>
<TableCell align="right">DOCTORCALL
AVERAGE </TableCell>
<TableCell align="right">CHEMIST
CALL
AVERAGE</TableCell>
<TableCell align="right">FREQVISIT
COMPLIANCE
%
</TableCell>
</TableRow> */}
          <TableRow
            style={{ color: '#6767B3', borderBottom: '3.4px solid #2c809c', borderTop: 'none' }}
          >
            {content?.tableFieldData?.map((item, index) =>
              item?.fld_l === 'Name' ? (
                <TableCell
                  key={index}
                  style={{ color: '#6767B3', fontWeight: '700' }}
                  align="start"
                >
                  {item?.fld_l === 'Headquarter' ? '' : item?.fld_l}
                  {/* {console.log(item)} */}
                </TableCell>
              ) : (
                <TableCell
                  key={index}
                  style={{ color: '#6767B3', fontWeight: '700' }}
                  align="center"
                >
                  {item?.fld_l === 'Headquarter' ? '' : item?.fld_l}
                  {/* {console.log(item)} */}
                </TableCell>
              )
            )}
          </TableRow>

          {/* <TableRow>
            <TableCell colSpan={2}>
              <span style={{ color: 'grey' }}>Weekly Norms</span>
            </TableCell>

            <TableCell colSpan={2}> </TableCell>
            <TableCell align="center" style={{ color: 'grey' }}>
              30
            </TableCell>
            <TableCell align="center" style={{ color: 'grey' }}>
              35
            </TableCell>
            <TableCell align="center" style={{ color: 'grey' }}>
              13
            </TableCell>
            <TableCell align="center" style={{ color: 'grey' }}>
              13
            </TableCell>
            <TableCell align="center" style={{ color: 'grey' }}>
              7
            </TableCell>
            <TableCell align="center" style={{ color: 'grey' }}>
              0
            </TableCell>
            {content.tbl_id === 'slm_kpi_dshb' ? (
              <TableCell align="center" style={{ color: 'grey' }}>
                45
              </TableCell>
            ) : (
              ''
            )}
          </TableRow> */}

          {/* <TableRow>
            <TableCell colSpan={2} align="left">
              <span style={{ color: 'grey' }}>Weightage</span>
            </TableCell>

            <TableCell colSpan={2}> </TableCell>
            <TableCell align="center" style={{ color: 'grey' }}>
              20
            </TableCell>
            <TableCell align="center" style={{ color: 'grey' }}>
              20
            </TableCell>
            <TableCell align="center" style={{ color: 'grey' }}>
              20
            </TableCell>
            <TableCell align="center" style={{ color: 'grey' }}>
              10
            </TableCell>
            <TableCell align="center" style={{ color: 'grey' }}>
              10
            </TableCell>
            <TableCell align="center" style={{ color: 'grey' }}>
              20
            </TableCell>
            {content.tbl_id === 'slm_kpi_dshb' ? (
              <TableCell align="center" style={{ color: 'grey' }}>
                25
              </TableCell>
            ) : (
              ''
            )}
          </TableRow> */}
        </TableHead>

        <TableBody>
          {generateData().map((row, index) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                color: '#666666',
                fontSize: '15px',
                borderBottom: index === 1 ? '3px solid #666666' : 'none',
                borderTop: 'none'
              }}
            >
              {/* for slm-leaderboard only  */}
              {content?.tbl_id === 'flm_kpi_dshb' ? (
                <>
                  <TableCell component="th" scope="row" style={{ display: 'flex' }}>
                    <Box
                      style={{
                        minWidth: '6px',
                        minHeight: '2px',
                        backgroundColor: row.flm_rnk_bar ? row.flm_rnk_bar : 'transparent',
                        marginRight: '5px'
                      }}
                    ></Box>

                    <Typography sx={{ color: row.flm_rnk ? '#000000' : 'transparent' }}>
                      {row.flm_rnk ? row.flm_rnk : '-'}
                    </Typography>
                  </TableCell>

                  <TableCell align="start">
                    <Typography noWrap>
                      {row?.flm_rnk_bar === '#59A14F' ? `${row.o_emp_name} (Me)` : row.o_emp_name}
                    </Typography>
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: row.flm_scr_bg_colr
                        ? row.flm_scr_bg_colr
                        : row.slm_scr_bg_colr
                        ? row.slm_scr_bg_colr
                        : '#FFFFFF',
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.slm_scr || row.flm_scr}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      color: row.slm_unique_doctor_met_text_colr
                        ? row.slm_unique_doctor_met_text_colr
                        : row.flm_unique_doctor_met_text_colr
                        ? row.flm_unique_doctor_met_text_colr
                        : '#666666',
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.slm_unique_doctor_met || row.flm_unique_doctor_met}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      color: row.flm_unique_doc_met_a_a_plus_text_colr
                        ? row.flm_unique_doc_met_a_a_plus_text_colr
                        : row.slm_total_unique_doc_met_a_a_plus_text_colr
                        ? row.slm_total_unique_doc_met_a_a_plus_text_colr
                        : '#666666',
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.slm_total_unique_doc_met_a_a_plus || row.flm_unique_doc_met_a_a_plus}
                  </TableCell>

                  <TableCell align="center" style={{ fontSize: '15px', fontWeight: '600' }}>
                    {/* {`${row.slm_leading_coverage}%` || `${row?.flm_leading_coverage}%`} */}
                    {row.slm_leading_coverage
                      ? row.slm_leading_coverage
                      : row?.flm_leading_coverage
                      ? row?.flm_leading_coverage
                      : ''}
                  </TableCell>

                  {/* <TableCell
                    align="center"
                    style={{
                      color:
                        row.slm_avg_leading_coverage || row.flm_avg_leading_coverage > 13
                          ? 'black'
                          : 'red'
                    }}
                  >
                    {row.slm_avg_leading_coverage + '%' || row.flm_avg_leading_coverage + '%'}
                  </TableCell> */}

                  <TableCell
                    align="center"
                    style={{
                      color: row.slm_doctor_call_avg_text_colr
                        ? row.slm_doctor_call_avg_text_colr
                        : '#666666',
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.slm_doctor_call_avg || row.flm_doctor_call_avg}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      color: row.flm_chemist_call_avg_text_colr
                        ? row.flm_chemist_call_avg_text_colr
                        : '#666666',
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.slm_chemist_call_avg || row.flm_chemist_call_avg}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: row.flm_avg_rcpa_text_colr ? row.flm_avg_rcpa_text_colr : '#666666',
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.slm_avg_rcpa || row.flm_avg_rcpa}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color:
                        row.slm_frqvstcmplnce || row.flm_frqvstcmplnce_text_colr
                          ? row.flm_frqvstcmplnce_text_colr
                          : '#666666',
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.slm_frqvstcmplnce || row.flm_frqvstcmplnce}
                  </TableCell>
                </>
              ) : content?.tbl_id === 'slm_kpi_dshb' ? (
                <>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ display: row?.slm_rnk ? 'flex' : 'table-cell' }}
                  >
                    <Box
                      style={{
                        minWidth: '6px',
                        minHeight: '2px',
                        backgroundColor: row.slm_rnk_bar ? row.slm_rnk_bar : 'transparent',
                        marginRight: '5px'
                      }}
                    ></Box>
                    <Typography>{row.slm_rnk}</Typography>
                  </TableCell>

                  <TableCell align="start">
                    <Typography noWrap>
                      {row?.slm_rnk_bar === '#59A14F' ? `${row.o_emp_name} (Me)` : row.o_emp_name}
                    </Typography>
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: row.slm_scr_bg_colr ? row.slm_scr_bg_colr : '#FFFFFF'
                    }}
                  >
                    {row.slm_scr}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      color: row.slm_unique_doctor_met_text_colr
                        ? row.slm_unique_doctor_met_text_colr
                        : '#666666',
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.slm_total_unique_doc_met_a_a_plus}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      color: row.slm_total_unique_doc_met_a_a_plus_text_colr
                        ? row.slm_total_unique_doc_met_a_a_plus_text_colr
                        : '#666666',
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.slm_unique_doctor_met}
                  </TableCell>

                  <TableCell align="center" style={{ fontSize: '15px', fontWeight: '600' }}>
                    {/* {`${row.slm_leading_coverage}%` || `${row?.flm_leading_coverage}%`} */}
                    {row.slm_leading_coverage ? row.slm_leading_coverage : ''}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      color: row?.slm_avg_leading_coverage,
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.slm_avg_leading_coverage}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      color: row.slm_doctor_call_avg_text_colr
                        ? row.slm_doctor_call_avg_text_colr
                        : '#666666',
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.slm_doctor_call_avg}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{
                      color: row.slm_chemist_call_avg_text_colr
                        ? row.slm_chemist_call_avg_text_colr
                        : '#666666',
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.slm_chemist_call_avg}
                  </TableCell>

                  {/* <TableCell
                    align="center"
                    style={{
                      color: row.flm_avg_rcpa_text_colr ? row.flm_avg_rcpa_text_colr : '#000000'
                    }}
                  >
                    {row.slm_avg_rcpa}
                  </TableCell> */}

                  <TableCell
                    align="center"
                    style={{
                      color: row?.slm_frqvstcmplnce,
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.slm_frqvstcmplnce}
                  </TableCell>
                </>
              ) : (
                // else part is here
                <>
                  <TableCell component="th" scope="row" style={{ display: 'flex' }}>
                    <Box
                      style={{
                        minWidth: '6px',
                        minHeight: '2px',
                        backgroundColor: row.rnk_bar
                          ? row.rnk_bar
                          : row.rnk_slm_bar
                          ? row.rnk_slm_bar
                          : 'transparent',
                        marginRight: '5px'
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        color: row.rnk ? '#000000' : row.rnk_slm ? '#000000' : 'transparent',
                        fontSize: '16px',
                        fontWeight: '600'
                      }}
                    >
                      {row.rnk ? row.rnk : row.rnk_slm ? row.rnk_slm : '-'}
                    </Typography>
                  </TableCell>

                  <TableCell align="start">
                    <Typography noWrap>
                      {row.rnk_bar === '#59A14F'
                        ? `${row.o_emp_name} (Me)`
                        : row.rnk_slm_bar === '#59A14F'
                        ? `${row.o_emp_name} (Me)`
                        : row.o_emp_name}
                    </Typography>
                  </TableCell>
                  {/* <TableCell align="center" style={{ color: 'grey' }}> */}
                  {/* {row.hdqtr} */}
                  {/* </TableCell> */}
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: row.scr_bg_colr
                        ? row.scr_bg_colr
                        : row.scr_slm_bg_colr
                        ? row.scr_slm_bg_colr
                        : '#FFFFFF',
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.scr || row.scr_slm}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: row.drcvrg || row.drcvrg_slm,
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.drcvrg || row.drcvrg_slm}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: row.kycdrcvrg || row.kycdrcvrg_slm,
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.kycdrcvrg || row.kycdrcvrg_slm}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: row.kycdrrcpa || row.kycdrrcpa_slm,
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.kycdrrcpa || row.kycdrrcpa_slm}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: row.drcllavg || row.drcllavg_slm,
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.drcllavg || row.drcllavg_slm}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: row.chmtcllavg_text_colr || row.chmtcllavg_slm_text_colr,
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.chmtcllavg || row.chmtcllavg_slm}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      color: row.frqvstcmplnce_slm_text_colr || row.frqvstcmplnce_text_colr,
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                  >
                    {row.frqvstcmplnce || row.frqvstcmplnce_slm}
                  </TableCell>
                </>
              )}
              {/* **********slm-leaderboard ends here***** */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
CustomTable.propTypes = {
  content: PropTypes.object,
  layout: PropTypes.object,
  apidata: PropTypes.object
};

export default CustomTable;
