import { Divider, Grid, MenuItem, MenuList, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Section from '../../components/Section/Section';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// Below are mui tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
function BasicTabs({
  tabsData,
  layout,
  check,
  pageId,
  setCheck,
  dynamic_page,
  viewplrundata,
  SetViewPlrunData,
  initialValues_data
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Typography textAlign="center" variant="h6" gutterBottom>
          {tabsData?.lbl || tabsData?.sctn_id}
        </Typography>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabsData?.children?.map((i) => (
            <Tab key={i?.sctn_id} label={i?.lbl || i?.sctn_id} />
          ))}
        </Tabs>
      </Box>

      {tabsData?.children?.map((i, j) => (
        <CustomTabPanel value={value} index={j} key={i?.sctn_id}>
          <Section
            pageId={pageId}
            data={{
              lnkd_pg_id: tabsData?.children?.[j]?.['pg_id'],
              cntnt: tabsData?.children?.[j]?.['sctn_id']
            }}
            layout={layout}
            check={check}
            setCheck={setCheck}
            dynamic_page={dynamic_page}
            viewplrundata={viewplrundata}
            SetViewPlrunData={SetViewPlrunData}
            initialValues_data={initialValues_data}
          />
        </CustomTabPanel>
      ))}
    </Box>
  );
}
BasicTabs.propTypes = {
  tabsData: PropTypes.object,
  layout: PropTypes.object,
  check: PropTypes.any,
  setCheck: PropTypes.func,
  dynamic_page: PropTypes.any,
  viewplrundata: PropTypes.any,
  SetViewPlrunData: PropTypes.func,
  initialValues_data: PropTypes.array,
  pageId: PropTypes.any
};
// Mui tabs ends here

const VerticalTabLayout = ({
  items,
  isParentActive,
  isChild,
  parentMenuItemClickHandler,
  layout,
  targetVerticalTab,
  setTargetVerticalTab,
  pageId,
  check,
  setCheck,
  dynamic_page,
  viewplrundata,
  SetViewPlrunData,
  initialValues_data
}) => {
  const [active, setActive] = useState(null);
  const [parent, setParent] = useState([]);
  const [parent_t, setParent_t] = useState(false);

  const menuItemClickHandler = (current) => {
    setActive(current);
  };

  return Array.isArray(items) ? (
    <Grid container spacing={1} pl={isChild ? 2 : 'none'}>
      <Grid item container xs={isChild ? 12 : 3}>
        <MenuList component={Grid} item xs>
          {items?.map((child) => [
            <MenuItem
              key={child?.sctn_id}
              onClick={() => {
                menuItemClickHandler(child?.sctn_id);
                if (isChild) {
                  parentMenuItemClickHandler();
                }
                if (child?.children && child?.children?.length && child?.dvd_in === 'R') {
                  setTargetVerticalTab(null);
                  setParent(child?.children);
                  return;
                }
                if (child?.children && child?.children?.length && child?.dvd_in === 'T') {
                  setParent_t(!parent_t);
                  return;
                } else {
                  setTargetVerticalTab(child?.sctn_id);
                  setParent([]);
                }
              }}
              selected={active === child?.sctn_id && isParentActive}
            >
              <Typography
                variant="body2"
                color={active === child?.sctn_id && isParentActive ? 'inherit' : 'gray'}
              >
                {child?.lbl || child?.sctn_id}
              </Typography>
            </MenuItem>
            // Array.isArray(child?.children) && child?.children?.length ? (
            //   <VerticalTabLayout
            //     items={child?.children}
            //     isChild
            //     isParentActive={active === child?.sctn_id}
            //     parentMenuItemClickHandler={() => menuItemClickHandler(child?.sctn_id)}
            //     targetVerticalTab={targetVerticalTab}
            //     setTargetVerticalTab={setTargetVerticalTab}
            //   />
            // ) : null
          ])}
        </MenuList>

        {isChild ? null : (
          <Divider orientation="vertical" item component={Grid} flexItem variant="middle" />
        )}
      </Grid>

      {/* {parent?.length ? (
        <Grid item xs={9}>
          {parent?.map((i) => (
            <Section
              key={i?.sctn_id}
              data={{ lnkd_pg_id: layout?.pg_id, cntnt: i?.sctn_id }}
              layout={layout}
              pageId={pageId}
              check={check}
              setCheck={setCheck}
              dynamic_page={dynamic_page}
              viewplrundata={viewplrundata}
              SetViewPlrunData={SetViewPlrunData}
              initialValues_data={initialValues_data}
            />
          )
          )}
        </Grid>
      ) : null} */}

      {parent?.length ? null : (
        <Grid item xs={9}>
          <Section
            data={{ lnkd_pg_id: layout?.pg_id, cntnt: targetVerticalTab }}
            layout={layout}
            pageId={pageId}
            check={check}
            setCheck={setCheck}
            dynamic_page={dynamic_page}
            viewplrundata={viewplrundata}
            SetViewPlrunData={SetViewPlrunData}
            initialValues_data={initialValues_data}
          />
        </Grid>
      )}

      {parent?.length ? (
        <Grid item xs={9}>
          {parent?.map((i) =>
            i?.children && i?.children?.length && i?.dvd_in === 'T' ? (
              <Grid item xs={9} key={i?.sctn_id}>
                <Box sx={{ maxWidth: 'none' }}>
                  <BasicTabs
                    tabsData={i}
                    layout={layout}
                    pageId={pageId}
                    check={check}
                    setCheck={setCheck}
                    dynamic_page={dynamic_page}
                    viewplrundata={viewplrundata}
                    SetViewPlrunData={SetViewPlrunData}
                    initialValues_data={initialValues_data}
                  />
                </Box>
              </Grid>
            ) : (
              <Section
                key={i?.sctn_id}
                data={{ lnkd_pg_id: layout?.pg_id, cntnt: i?.sctn_id }}
                layout={layout}
                pageId={pageId}
                check={check}
                setCheck={setCheck}
                dynamic_page={dynamic_page}
                viewplrundata={viewplrundata}
                SetViewPlrunData={SetViewPlrunData}
                initialValues_data={initialValues_data}
              />
            )
          )}
        </Grid>
      ) : null}
    </Grid>
  ) : null;
};

VerticalTabLayout.defaultProps = {
  isChild: false,
  isParentActive: true
};

VerticalTabLayout.propTypes = {
  items: PropTypes.array.isRequired,
  isChild: PropTypes.bool,
  isParentActive: PropTypes.bool,
  parentMenuItemClickHandler: PropTypes.func,
  layout: PropTypes.object,
  targetVerticalTab: PropTypes.object,
  setTargetVerticalTab: PropTypes.func,
  pageId: PropTypes.any,
  check: PropTypes.any,
  setCheck: PropTypes.func,
  dynamic_page: PropTypes.any,
  viewplrundata: PropTypes.any,
  SetViewPlrunData: PropTypes.func,
  initialValues_data: PropTypes.array
};

export default VerticalTabLayout;
