import React, { useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from 'recharts';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

const colors = ['#2293cb', '#f10a79', '#0f3460'];

const VerticalBarcharBig = ({ apidata, item, chrt_id }) => {
  const chart_data = useMemo(() => {
    if (chrt_id) {
      let filterd_data = apidata?.chart?.filter((items) => items?.[chrt_id]);
      return filterd_data?.[0]?.[chrt_id];
    }
  }, [apidata, chrt_id]);
  // const colors = ['#FF5733', '#3366FF', '#33FF57', '#FF33A1'];

  let q = [];
  for (const i of chart_data?.chartTableData || []) {
    let obj = {};
    for (const j of item?.pageContent?.[0]?.chartDetail || []) {
      if (j.axis === 'x') {
        obj[j.fld_id] = i[j.fld_id];
      } else {
        obj[j.ttl] = i[j.fld_id];
      }
    }
    q.push(obj);
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload) {
      const [barData] = payload;
      if (barData !== undefined && barData !== null) {
        // Access properties or methods here
        return (
          <>
            <Card sx={{ maxWidth: 300 }}>
              <CardContent>
                {Object.keys(barData.payload).map((key) => (
                  <Typography key={key} noWra fontWeight="bold">
                    {key}:{' '}
                    {key === 'Frequency Compliance'
                      ? barData.payload[key] + '%'
                      : barData.payload[key]}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </>
        );
      }
    }
    return null;
  };

  CustomTooltip.propTypes = {
    active: PropTypes.any,
    payload: PropTypes.any,
    value: PropTypes.any
  };
  // console.log('data@', chart_data, q);

  // get max value of bar
  const maxValueBar = useMemo(() => {
    if (q.length) {
      const filterObject = item?.pageContent?.[0]?.chartDetail
        .filter((item) => item.axis === 'y' && item.kf_ty === 'bar')
        .map((data) => data.ttl);
      const properties = filterObject; // Array of dynamic property names

      const maxValue = q.reduce((max, d) => {
        // Map the dynamic properties to their values for the current object 'd'
        const values = properties.map((prop) => d[prop]);
        // Find the maximum value among the properties for the current object
        const value = Math.max(...values);
        // Update the overall maximum value
        return value > max ? value : max;
      }, -Infinity);

      const increasedMaxValue = Math.floor(maxValue * 1.1);
      return increasedMaxValue;
    }
  }, [q]);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={730}
        height={300}
        data={q || []}
        margin={{
          top: 30,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        {item?.pageContent?.[0]?.chartDetail
          ?.filter((item) => item.axis === 'x')
          .map((obj) => (
            <XAxis
              key={obj.fld_id}
              dataKey={obj.fld_id}
              interval={0}
              tickFormatter={(value) => (value.length > 5 ? `${value.slice(0, 5)}..` : value)}
              tick={{ fill: '#5500ff' }}
            />
          ))}
        <YAxis hide domain={[0, maxValueBar]} />
        <Tooltip content={<CustomTooltip />} />
        {/* {item?.pageContent?.[0]?.is_legend === 'n' &&  <Legend />} */}
        {item?.pageContent?.[0]?.is_legend === '' && <Legend />}
        {item?.pageContent?.[0]?.chartDetail
          ?.filter((item) => item.axis === 'y')
          .map((obj, index) => (
            <Bar key={obj.fld_id} dataKey={obj.ttl} barSize={50} fill={colors[index]}>
              <LabelList dataKey={obj.ttl} position="top" allowOverflow={true} fill="black" />
            </Bar>
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default VerticalBarcharBig;
VerticalBarcharBig.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object,
  item: PropTypes.object,
  chrt_id: PropTypes.string
};
