import React from 'react';
import Box from '@mui/material/Box';
import AlignedTables from '../AlignedTables/AlignedTables';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
const MiniSizeTable = ({ layout, apidata, content }) => {
  // const chart_data = useMemo(() => {
  //   if (content) {
  //     const table_id = content?.tbl_id;
  //     const api_data = apidata?.allTablesData;
  //     let filterd_data = api_data?.filter((items) => items?.[table_id]);
  //     return filterd_data?.[0]?.[table_id];
  //   }
  // }, [apidata, layout?.sctn_id]);

  return (
    <Box display="flex" justifyContent="center">
      <Box
        component="section"
        sx={{
          p: 2,
          // border: '1px dashed grey',
          height: '180px',
          backgroundColor: content?.bg_colr,
          borderRadius: '40px',
          display: 'table'
        }}
      >
        <Typography variant="button" display="block" fontWeight="900" textAlign="center">
          {content?.tbl_nm}
        </Typography>
        <Divider />
        <AlignedTables layout={layout} apidata={apidata} content={content} />
      </Box>
    </Box>
  );
};

export default MiniSizeTable;
MiniSizeTable.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object
};
