import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import { useState } from 'react';

const Upload = () => {
  const [files, setFiles] = useState([]);
  registerPlugin(FilePondPluginFileValidateType);

  return (
    <div style={{ width: '100%', flex: 2, paddingTop: '16px', paddingLeft: '16px' }}>
      <FilePond
        files={files}
        onupdatefiles={setFiles}
        allowMultiple={false}
        allowBrowse
        name="upload"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        server="/api"
        credits={false}
      />
    </div>
  );
};

export default Upload;
