import PropTypes from 'prop-types';
import { Container, Stack } from '@mui/material';

const GuestLayout = ({ children }) => {
  return (
    <Container sx={{ height: '100vh' }}>
      <Stack justifyContent="center" height="inherit">
        {children}
      </Stack>
    </Container>
  );
};

GuestLayout.propTypes = {
  children: PropTypes.node
};

export default GuestLayout;
