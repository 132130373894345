import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import PropTypes from 'prop-types';

// const data = [
//   {
//     name: 'Page A',
//     uv: 4000,
//     pv: 2400,
//     amt: 2400
//   },
//   {
//     name: 'Page B',
//     uv: 3000,
//     pv: 1398,
//     amt: 2210
//   },
//   {
//     name: 'Page C',
//     uv: 2000,
//     pv: 9800,
//     amt: 2290
//   },
//   {
//     name: 'Page D',
//     uv: 2780,
//     pv: 3908,
//     amt: 2000
//   },
//   {
//     name: 'Page E',
//     uv: 1890,
//     pv: 4800,
//     amt: 2181
//   },
//   {
//     name: 'Page F',
//     uv: 2390,
//     pv: 3800,
//     amt: 2500
//   },
//   {
//     name: 'Page G',
//     uv: 3490,
//     pv: 4300,
//     amt: 2100
//   }
// ];

const bb = [
  {
    act_doc_ct: 0,
    splty: 'Cardiologist'
  },
  {
    act_doc_ct: 0,
    splty: 'Consultant'
  },
  {
    act_doc_ct: 0,
    splty: 'Dentist'
  },
  {
    act_doc_ct: 0,
    splty: 'Dermatologist'
  },
  {
    act_doc_ct: 2,
    splty: 'Diabetic Specialist'
  },
  {
    act_doc_ct: 0,
    splty: 'ENT'
  },
  {
    act_doc_ct: 1,
    splty: 'ENT Surgeon'
  },
  {
    act_doc_ct: 10,
    splty: 'GP'
  },
  {
    act_doc_ct: 0,
    splty: 'GP-Chest&TB'
  },
  {
    act_doc_ct: 2,
    splty: 'GP-Gynaecologist'
  },
  {
    act_doc_ct: 1,
    splty: 'GP-Gyne'
  },
  {
    act_doc_ct: 1,
    splty: 'Gynec-IVF'
  },
  {
    act_doc_ct: 1,
    splty: 'MBBS-GP'
  },
  {
    act_doc_ct: 0,
    splty: 'Non-MBBS-GP'
  },
  {
    act_doc_ct: 0,
    splty: 'Opthalmologist'
  },
  {
    act_doc_ct: 0,
    splty: 'Orthopedic'
  },
  {
    act_doc_ct: 7,
    splty: 'Pediatrician'
  },
  {
    act_doc_ct: 4,
    splty: 'Physician'
  },
  {
    act_doc_ct: 1,
    splty: 'Surgeon'
  }
];
const CustomYAxisTick = ({ x, y, payload }) => (
  <text x={x - 80} y={y} textAnchor="center" dy={3} fill="#666" fontSize="10px" fontWeight="500">
    {payload.value}
  </text>
);
CustomYAxisTick.propTypes = {
  x: PropTypes.any,
  y: PropTypes.any,
  payload: PropTypes.any,
  chrt_id: PropTypes.string,
  item: PropTypes.object,
  itemData: PropTypes.object
};

export default function Chrt() {
  return (
    <BarChart
      width={500}
      height={300}
      data={bb}
      margin={{
        top: 5,
        right: 30,
        left: 50,
        bottom: 5
      }}
      layout="vertical"
    >
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <XAxis hide type="number" />
      <YAxis type="category" dataKey="splty" interval={0} tick={<CustomYAxisTick />} />
      <Tooltip shared={false} trigger="click" />
      <Legend />
      <Bar dataKey="act_doc_ct" fill="#8884d8">
        lol
      </Bar>
    </BarChart>
  );
}
