import './App.css';
// import Dashboard from './pages/Dashboard/Dashboard';
import { RouterProvider, createBrowserRouter, defer } from 'react-router-dom';
import AppDetails from './pages/AppDetails/AppDetails';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppPage from './pages/AppPage/AppPage';
import Http from './utils/http';
import Loader from './components/Loader/Loader';
import CardMUILogoutPage from './components/Navbar/CardMuiLogoutPage';
import TestingOidc from './components/TestingOidc/TestingOidc';
import AuthRedirect from './components/AuthRedirect/AuthRedirect';
// import AlignedTables from './components/AlignedTables/AlignedTables';
import MultiColumnProgressBar from './components/MultiColumnProgressBar/MultiColumnProgressBar';
import ReactProgressBar from './components/ReactProgressBar/ReactProgressBar';
import StackedBarChart from './components/AllTypeChart/StackedBarChart';
import Chrt from './components/TestingComponents/Charts';
import LandingPage from './layouts/LandingPage/LandingPage';
import MultipleSelectCheckmarks from './components/CheckBoxDropDown/MultipleSelectCheckmarks';
import LabTabs from './components/MuiTabsPractice/MuiTabsPractice';
import MultiSelectCheckMarks from './components/MultiSelectCheckMarks/MultiSelectCheckMarks';
import SelectSmall from './components/MultiSelectCheckMarks/BasicSelect';
import IncentiveTable from './components/CompboTable/IncentiveTable/IncentiveTable';
// import Example from './components/AllTestingComponents/MRTTable/TestingIncentives';
import Appqq from './components/AllTestingComponents/MRTTable/JWTToken';
import InvalidUser from './components/Navbar/InvalidUser';
import ExampleTable from './components/AllTestingComponents/MRTTable/TestingMRTCheckBox';
import TestingHorizontalBar from './components/AllTestingComponents/MRTTable/TestingHorizontalBar';
import LineChartComponent from './components/AllTypeChart/LineChart';
import TestingColumnclickAddrow from './components/AllTestingComponents/MRTTable/TestingColumnclickAddrow';
import BaseTable from './components/AllTestingComponents/MRTTable/PerformanceTable/BaseTable';
import AlignedTables from './components/AlignedTables/AlignedTables';
import AgGridTestTable from './components/AllTestingComponents/AgGridTable/AgGridTestTable';

// import GraphTest from './components/GraphTest/GraphTest';
// import queryString from 'query-string';
// import { Counter } from './features/counter/Counter';
// import CustomTable from './components/CutomTable/CustomTable';

const App = () => {
  // const fetchPageStructure = async () => {
  //   // const accessToken = localStorage.getItem('access_token');
  //   // if an access token doesn't exist in the local storage, or if login session has expired, redirect the user to login
  //   // if (!accessToken || localStorage.getItem('expiry') < Date.now()) {
  //   //   window.location.href = '/login';
  //   // } else {
  //   //   Http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  //   // }
  //   const pageContent = Http.get('get-page-content');
  //   const pageContentData = Http.get('get-page-content-data');
  //   const menu = Http.get('get-client-app-menu');
  //   const pageSections = Http.get('get-page-section');
  //   return defer({
  //     bundle: Promise.all([pageContent, pageContentData, menu, pageSections])
  //   });
  // };

  // using this function to get emp_id which ius stored in localstorage version 2

  const fetchPageStructure = async () => {
    // const accessToken = localStorage.getItem('access_token');
    // if an access token doesn't exist in the local storage, or if login session has expired, redirect the user to login
    // if (!accessToken || localStorage.getItem('expiry') < Date.now()) {
    //   window.location.href = '/login';
    // } else {
    //   Http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    // }

    // const emp_id = localStorage.getItem('emp_id');
    // console.log("emp_id@#",emp_id)
    // if (!emp_id) {
    //   window.location.href = '/';
    // }

    // if logout and tried to access this route then redirect to login page.
    const EmployeID = localStorage.getItem('emp_id');
    if (!EmployeID) {
      window.location.href = '/login';
    }
    const emp_id = localStorage.getItem('emp_id');
    const role_id = localStorage.getItem('role_id');
    const pageContent = Http.post('get-page-content', {
      emp_id: `${emp_id}`,
      role_id: `${role_id}`
    });
    const pageContentData = Http.post('get-page-content-data', {
      emp_id: `${emp_id}`,
      role_id: `${role_id}`
    });
    // const menu = Http.post('get-client-app-menu', { emp_id: `${emp_id}` });
    const pageSections = Http.post('get-page-section', {
      emp_id: `${emp_id}`,
      role_id: `${role_id}`
    });
    return defer({
      bundle: Promise.all([pageContent, pageContentData, pageSections])
    });
  };

  const router = createBrowserRouter([
    {
      path: '/sso',
      element: <TestingOidc />
    },
    {
      path: '/auth',
      element: <AuthRedirect />
    },
    {
      path: '/logout',
      element: <CardMUILogoutPage />
    },

    {
      path: '/register',
      element: <Register />
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/datatable',
      element: <IncentiveTable />
    },
    {
      path: '/linechart',
      element: <LineChartComponent />
    },
    {
      path: '/table1',
      element: <ExampleTable />
    },
    {
      path: '/agtable',
      element: <AgGridTestTable />
    },
    {
      path: '/table2',
      element: <TestingColumnclickAddrow />
    },
    {
      path: '/basictable',
      element: <AlignedTables />
    },
    {
      path: '/base',
      element: <BaseTable />
    },
    {
      path: '/bar1',
      element: <TestingHorizontalBar />
    },
    {
      path: '/progress',
      element: <MultiColumnProgressBar />
    },
    {
      path: '/progress1',
      element: <ReactProgressBar />
    },
    {
      path: '/charts',
      element: <StackedBarChart />
    },
    {
      path: '/chrt',
      element: <Chrt />
    },
    {
      path: '/',
      element: <LandingPage />
    },
    {
      path: '/checkbox',
      element: <MultipleSelectCheckmarks />
    },
    {
      path: '/invaliduser',
      element: <InvalidUser />
    },
    {
      path: '/checkbox1',
      element: <SelectSmall />
    },
    {
      path: '/test',
      element: <MultiSelectCheckMarks />
    },
    {
      path: '/icons',
      element: <LabTabs />
    },
    {
      path: '/jwt',
      element: <Appqq />
    },
    {
      path: '/app/:appId',
      element: <AppDetails />,
      children: [
        {
          path: ':slug',
          element: <AppPage />,
          loader: fetchPageStructure,
          errorElement: <>Route Error</>
        }
      ]
    }
  ]);

  return (
    <ThemeProvider theme={theme}>
      {/* <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/app/:appId" element={<AppDetails />}>
            <Route path="/app/:appId/:slug" element={<AppPage />} />
          </Route>
        </Routes>
      </BrowserRouter> */}
      <RouterProvider router={router} fallbackElement={<Loader />} />
      <ToastContainer />
    </ThemeProvider>
  );
};

export default App;
