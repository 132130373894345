import { useMemo, useState, useEffect } from 'react';
import * as React from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const names = ['firstName', 'lastName', 'address', 'city', 'state'];

const Appqq = () => {
  const [table1, setTable1] = useState({ address: true, city: false, state: false });
  const [personName, setPersonName] = React.useState(['firstName', 'lastName']);

  const objColumns = personName.map((item) => ({ [item]: true }));
  const mergedObject = Object.assign({}, ...objColumns);

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    console.log('value...', value);
  };

  console.log('personName...', personName, mergedObject);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'firstName',
        enableHiding: false,
        header: 'First Name'
      },
      {
        accessorKey: 'lastName',
        enableHiding: false,
        header: 'Last Name'
      },
      {
        accessorKey: 'address',
        header: 'Address'
      },
      {
        accessorKey: 'city',
        header: 'City'
      },
      {
        accessorKey: 'state',
        header: 'State'
      }
    ],
    [table1.address, table1.city, table1.state]
  );

  const data = useMemo(
    () => [
      //data definitions...
      {
        firstName: 'Dylan',
        lastName: 'Murray',
        address: '261 Erdman Ford',
        city: 'East Daphne',
        state: 'Kentucky'
      },
      {
        firstName: 'Raquel',
        lastName: 'Kohler',
        address: '769 Dominic Grove',
        city: 'Columbus',
        state: 'Ohio'
      },
      {
        firstName: 'Ervin',
        lastName: 'Reinger',
        address: '566 Brakus Inlet',
        city: 'South Linda',
        state: 'West Virginia'
      },
      {
        firstName: 'Brittany',
        lastName: 'McCullough',
        address: '722 Emie Stream',
        city: 'Lincoln',
        state: 'Nebraska'
      },
      {
        firstName: 'Branson',
        lastName: 'Frami',
        address: '32188 Larkin Turnpike',
        city: 'Charleston',
        state: 'South Carolina'
      }
      //end
    ],
    [table1.city]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { columnVisibility: table1 }
  });

  const handleclick = () => {
    setTable1({
      address: true,
      city: true,
      state: true
    });
  };
  useEffect(() => {
    table.setColumnVisibility(table1);
  }, [table1, table]);

  console.log(table, table1);
  return (
    <>
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {names.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={personName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <MaterialReactTable table={table} />
      <button onClick={() => handleclick()}>click</button>
    </>
  );
};

export default Appqq;
