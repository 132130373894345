import PropTypes from 'prop-types';
import Http from '../../utils/http';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Button, Grid, Typography } from '@mui/material';
import DataTable from '../DataTable/DataTable';
import Field from '../Field/Field';
import Upload from '../Upload/Upload';
import Chart from '../Chart/Chart';
import { useFormik } from 'formik';
import TableCoulumnHyperLink from '../HyperlinkPage/TableCoulumnHyperLink';

// eslint-disable-next-line no-unused-vars
const Section = ({
  data,
  // eslint-disable-next-line no-unused-vars
  layoutItemData,
  layout,
  // eslint-disable-next-line no-unused-vars
  refetchPageData,
  check,
  setCheck,
  pageId,
  dynamic_page,
  viewplrundata,
  SetViewPlrunData,
  initialValues_data,
  gettablename
}) => {
  const [section, setSection] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [celldata, setCellData] = useState(null);

  const initialForm_values = useMemo(() => {
    let returnValue = {};
    if (initialValues_data?.[0]) {
      let keys = Object.keys(initialValues_data?.[0]) || [];
      if (keys?.length) {
        keys.forEach((key) => {
          returnValue[key] = '';
        });
      }
    }
    return returnValue;
  }, [initialValues_data]);

  const handleClose = (cellItem, hpr_pg_id) => {
    setIsOpen(true);
    setCellData({ ...cellItem, hpr_pg_id: hpr_pg_id });
  };

  // const fetchSectionData = async (pg_id, sctn_id) => {
  //   try {
  //     const response = await Http.post('/get-page-content-data-wrt-ids', { pg_id, sctn_id });
  //     if (!response.data?.error) {
  //       setSection(response.data?.data);
  //     } else {
  //       toast.error(response.data?.error);
  //     }
  //   } catch (e) {
  //     toast.error(e?.message);
  //   }
  // };

  const fetchSectionData = useCallback(
    async (pg_id, sctn_id) => {
      try {
        const response = await Http.post('/get-page-content-data-wrt-ids', { pg_id, sctn_id });
        if (!response.data?.error) {
          setSection(response.data?.data);
        } else {
          toast.error(response.data?.error);
        }
      } catch (e) {
        toast.error(e?.message);
      }
    },
    [section]
  );

  useEffect(() => {
    if (data) {
      if (data?.cntnt) {
        fetchSectionData(data?.lnkd_pg_id, data?.cntnt);
      }

      // else {
      //   // if a vertical tab layout is rendered, find the first tab item that has a valid sctn_id and display its details
      //   fetchSectionData(
      //     data?.lnkd_pg_id,
      //     layout?.children?.find((item) => !item?.dvd_in)?.sctn_id
      //   );
      // }
    }
  }, [data, check]);

  const apiCallForCreate = async (mappedObject, actionName) => {
    const mappedObjectLength = mappedObject.length;
    const checkEmpty = mappedObject.filter((i) => i?.['field_value'].length === 0);
    if (checkEmpty.length >= 1 || mappedObjectLength === 0) {
      SetViewPlrunData([]);
    } else {
      try {
        const response = await Http.post('/fetch-all-page-data', {
          a_id: '',
          pg_id: `${layout?.pg_id}`,
          field_values: mappedObject,
          action: {
            action_type: 'action',
            action_value: `${actionName?.[0]}`
          },
          sel_table: {}
        });
        if (!response.data?.error) {
          toast.success('Record created successfully');
          let apiData = response?.data?.data;
          setCheck([]);
          if (apiData?.['actionPerformed'] === 'view') {
            SetViewPlrunData([apiData]);
          }
        } else {
          toast.error(response.data?.error);
        }
      } catch (e) {
        toast.error(e?.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialForm_values || {},
    onSubmit: (values, { resetForm }) => {
      let actionName = section?.[0]?.pageContent
        ?.filter((content) => content?.cntnt_ty === 'action')
        ?.map((content) => content?.actn_id);
      const mappedObject = Object.entries(values).map(([field_name, field_value]) => {
        return { field_name, field_value };
      });
      apiCallForCreate(mappedObject, actionName);
      resetForm({ values: '' });
    }
  });

  const dynamicApiForAllTablev1 = async (page_id, tble_id, cellItem) => {
    try {
      const response = await Http.post('/fetch-all-page-data', {
        a_id: '',
        pg_id: `${page_id}`,
        field_values: {},
        action: {
          action_type: 'table_id',
          action_value: 'action'
        },
        sel_table: {
          table_id: `${tble_id}`,
          field_name: 'plrun_id',
          field_value: `${cellItem?.['row']?.['plrun_id']}`
        }
      });
      if (!response.data?.error) {
        if (tble_id && cellItem) {
          const b1 = response?.data?.data;
          setCheck(b1);
        }
      } else {
        toast.error(response.data?.error);
      }
    } catch (e) {
      toast.error(e?.message);
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        {section?.[0]?.pageContent?.map((content, pos) =>
          content?.cntnt_ty === 'table' ? (
            <Grid item xs key={`${layout?.sctn_id}-${content?.tbl_id}`}>
              <DataTable
                columns={content?.tableData?.map((item, index) => ({
                  field: item?.fld_id,
                  headerName:
                    item?.fld_ty === 'action' ? 'Action' : content?.tableFieldData?.[index]?.fld_l,
                  // flex: 1,
                  minWidth: parseInt(
                    content?.tableFieldData?.[index]?.lngth
                      ? parseInt(content?.tableFieldData?.[index]?.lngth) * 8
                      : 120
                  ),
                  renderCell: (cellItem) =>
                    item?.fld_ty === 'action' ? (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          dynamicApiForAllTablev1(layout?.pg_id, content?.tbl_id, cellItem);
                        }}
                      >
                        {item?.fld_id}
                      </Button>
                    ) : item?.is_hpr === '1' ? (
                      <Button
                        variant="text"
                        color="secondary"
                        style={{ backgroundColor: 'transparent' }}
                        onClick={() => handleClose(cellItem, item?.hpr_pg_id)}
                      >
                        {cellItem?.row?.[`${item?.fld_id}`] || 'N.A'}
                      </Button>
                    ) : content?.tableFieldData?.[index]?.hpr_pg_id !== '' ? (
                      <Button
                        variant="text"
                        color="secondary"
                        style={{ backgroundColor: 'transparent' }}
                        onClick={() =>
                          handleClose(cellItem, content?.tableFieldData?.[index]?.hpr_pg_id)
                        }
                      >
                        {cellItem?.row?.[`${item?.fld_id}`] || 'N.A'}
                      </Button>
                    ) : (
                      // <Field
                      //   field={content?.pageContent?.[0]?.tableFieldData?.[index]}
                      //   key={item?.fld_id}
                      // />

                      <Typography>{cellItem?.row?.[`${item?.fld_id}`] || 'N.A'}</Typography>
                    )
                }))}
                // rows={[
                //   {
                //     id: 1
                //   }
                // ]}
                tableHeading={<Typography variant="h6">{content?.tbl_nm || ''}</Typography>}
                tableName={content?.tbl_nm}
                check={check}
                setCheck={setCheck}
                techName={content?.tbl_id}
                pageId={pageId}
                dynamic_page={dynamic_page}
                viewplrundata={viewplrundata}
                SetViewPlrunData={SetViewPlrunData}
              />
            </Grid>
          ) : content?.cntnt_ty === 'upload' ? (
            <Upload key={`${layout?.sctn_id}-upload-${pos}`} />
          ) : content?.cntnt_ty === 'chart' ? (
            <Grid item xs key={content.cntnt_ty + content?.sctn_id}>
              <Chart
                key={content.cntnt_ty + content?.sctn_id}
                pageId={pageId}
                chart_id={section}
                check={check}
              />
            </Grid>
          ) : content?.cntnt_ty === 'section' ? (
            <Grid item xs key={content.cntnt_ty + content?.sctn_id}>
              <Section
                key={content.cntnt_ty + content?.sctn_id}
                layout={layout}
                data={content}
                check={check}
                setCheck={setCheck}
                pageId={pageId}
                dynamic_page={dynamic_page}
                viewplrundata={viewplrundata}
                SetViewPlrunData={SetViewPlrunData}
                initialValues_data={initialValues_data}
                gettablename={gettablename}
              />
            </Grid>
          ) : (
            <Grid item xs="auto" key={`${layout?.sctn_id}-${pos}`}>
              {content?.cntnt_ty === 'field' ? (
                <Field
                  field={content}
                  key={`${layout?.sctn_id}-${content?.fld_id}`}
                  id={`${layout?.sctn_id}-${content?.fld_id}`}
                  label={content?.fld_l}
                  onChange={formik.handleChange}
                  name={content?.fld_id}
                  value={formik.values?.[content?.fld_id]}
                  // value={Object.keys(formik.values).length >=1 ? formik.values?.[content?.fld_id] : ""}
                />
              ) : content?.cntnt_ty === 'action' ? (
                <Button
                  variant="contained"
                  key={`${layout?.sctn_id}-${content?.actn_id}`}
                  id={`${layout?.sctn_id}-${content?.actn_id}`}
                  sx={{ width: 'fit-content' }}
                  onClick={formik.handleSubmit}
                >
                  {content?.actn_nm}
                </Button>
              ) : (
                content?.cntnt_ty
              )}
            </Grid>
          )
        )}
      </Grid>
      <TableCoulumnHyperLink
        open={isOpen}
        setOpen={setIsOpen}
        layout={layout}
        celldata={celldata}
        pg_id={celldata?.hpr_pg_id}
      />
    </>
  );
};

Section.propTypes = {
  initialValues_data: PropTypes.array,
  data: PropTypes.object,
  layout: PropTypes.object,
  layoutItemData: PropTypes.array,
  refetchPageData: PropTypes.func,
  check: PropTypes.array,
  setCheck: PropTypes.func,
  pageId: PropTypes.string,
  dynamic_page: PropTypes.any,
  viewplrundata: PropTypes.any,
  SetViewPlrunData: PropTypes.func,
  gettablename: PropTypes.any
};

export default Section;
