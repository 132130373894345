import React from 'react';
import ProgressBar from '@ramonak/react-progress-bar';

const ReactProgressBar = () => {
  return (
    <div>
      <ProgressBar
        completed={3}
        width="20%"
        customLabel="pp"
        borderRadius="unset"
        labelAlignment="center"
      />

      <ProgressBar completed={'60'} labelAlignment="right" />
    </div>
  );
};
export default ReactProgressBar;
