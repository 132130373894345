import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

// Define rowSpan function
const rowSpan = (params) => {
  const make = params.data ? params.data.make : undefined;
  if (make === 'Tesla') {
    return 2; // Tesla rows should span by 2
  }
  return 1; // Other rows like Toyota should have a default span of 1
};

// Custom cell renderer for the 'make' column
const makeCellRenderer = (params) => {
  if (params.node.rowIndex % params.rowSpan !== 0) {
    // Hide content for rows that are part of a span but not the first row
    return null;
  }
  // Show the content for non-spanned rows or first row in a span
  return params.value;
};

const AgGridTestTable = () => {
  // Hardcoded row data for testing
  const [rowData] = useState([
    { make: 'Tesla', model: 'Model Y', price: 64950, electric: true },
    { make: 'Tesla', model: 'F-Series', price: 33850, electric: false },
    { make: 'Toyota', model: 'Corolla', price: 29600, electric: false }
  ]);

  // Column definitions with rowSpan and custom cell renderer for 'make'
  const [colDefs] = useState([
    {
      field: 'make',
      rowSpan: rowSpan, // Use rowSpan function
      cellRenderer: makeCellRenderer, // Use custom cellRenderer to hide repeated cells
      width: 200
    },
    { field: 'model' },
    { field: 'price' },
    { field: 'electric' }
  ]);

  return (
    // Wrapping container with theme & size
    <div
      className="ag-theme-quartz" // Apply the Data Grid theme
      style={{ height: 500, width: '100%' }} // The Data Grid will fill the size of the parent container
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
        suppressRowTransform={true} // Disable row virtualization to allow rowspan
      />
    </div>
  );
};

export default AgGridTestTable;
