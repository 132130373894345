import React, { useMemo } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, LabelList } from 'recharts';
import PropTypes from 'prop-types';

const DoubleHorizontalBar = ({ apidata, chrt_id, itemData }) => {
  const chart_data = useMemo(() => {
    if (chrt_id) {
      let filterd_data = apidata?.chart?.filter((items) => items?.[chrt_id]);
      return filterd_data?.[0]?.[chrt_id];
    }
  }, [apidata]);

  let q = [];
  for (const i of chart_data?.chartTableData || []) {
    let obj = {};
    for (const j of itemData?.chartDetail || []) {
      if (j.axis === 'x') {
        obj[j.fld_id] = i[j.fld_id];
      } else {
        obj[j?.fld_id] = i[j.fld_id];
      }
    }
    q.push(obj);
  }
  // console.log('q@@@', q, chrt_id, apidata, itemData);

  const DomainKey = chart_data?.chartDetail?.[0]?.['fld_id'];
  const maxActDocCt = q.reduce((max, current) => {
    const currentVal = parseInt(current[DomainKey]);
    return isNaN(currentVal) ? max : Math.max(max, currentVal);
  }, 0);
  const CustomYAxisTick = ({ x, y, payload }) => (
    <text
      x={x - 100}
      y={y}
      textAnchor="center"
      dy={3}
      fontSize="14px"
      fill="#000000"
      fontWeight="500"
    >
      {payload.value}
    </text>
  );

  CustomYAxisTick.propTypes = {
    x: PropTypes.any,
    y: PropTypes.any,
    payload: PropTypes.any,
    chrt_id: PropTypes.string,
    item: PropTypes.object,
    itemData: PropTypes.object
  };

  return (
    <>
      <ResponsiveContainer
        height={
          chrt_id === 'flm_c_a2_m2_s4.t2_t2_c1'
            ? 600
            : chrt_id === 'flm_c_a2_m2_s4.t2_t2_c2'
            ? 600
            : chrt_id === 'flm_c_a2_m2_s4.t2_t2_c3'
            ? 600
            : 400
        }
        width="150%"
      >
        <BarChart
          data={q || []}
          layout="vertical"
          margin={{ left: 50, right: 30, top: 20, bottom: 10 }}
        >
          <XAxis
            hide
            type="number"
            domain={[0, 'dataMax + ' + maxActDocCt]}
            padding={{ left: 70, right: 20 }}
          />
          <YAxis
            type="category"
            dataKey={itemData?.chartDetail?.[1]?.['fld_id'] || 'no name'}
            tickLine={false}
            axisLine={false}
            tick={<CustomYAxisTick />}
            interval={0}
            hide={itemData?.chartDetail?.[1]?.char_kf === 'hide' ? true : false}
            // padding={{ top: 10, bottom: 10 }}
          />
          <Bar
            dataKey={itemData?.chartDetail?.[0]?.['fld_id'] || 'no name'}
            fill="#6082B6"
            minPointSize={0}
            barSize={13}
            barGap={3}
            barCategoryGap={5}
            stroke="#000000"
            strokeWidth={1}
          >
            <LabelList
              dataKey={itemData?.chartDetail?.[0]?.['fld_id']}
              position="right"
              fill="#00000"
              fontSize={'15px'}
              formatter={(value) => (value !== 0 ? value : null)}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default DoubleHorizontalBar;

DoubleHorizontalBar.propTypes = {
  childLayout: PropTypes.array,
  layout: PropTypes.object,
  apidata: PropTypes.object,
  chrt_id: PropTypes.string,
  item: PropTypes.object,
  itemData: PropTypes.object
};
