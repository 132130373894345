import React from 'react';
import queryString from 'query-string';
import { useEffect, useState } from 'react';

const AuthRedirect = () => {
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    // Parse the id_token from the URL
    const response = queryString.parse(window.location.hash);
    // Fetch user info using the id_token
    fetch('https://mankindpharma-sandbox.onelogin.com/oidc/2/me', {
      headers: {
        Authorization: `Bearer ${response?.access_token}`
      }
    })
      .then((response) => response.json())
      .then((data) => setUserInfo(data))
      .catch((error) => console.error('Error fetching user info:', error));
  }, []);
  console.log('userInfo?..', userInfo);
  const response = queryString.parse(window.location.hash);
  console.log('response from hase', response);
  return <div>AuthRedirect</div>;
};

export default AuthRedirect;
