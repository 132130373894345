import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

export default function BasicTableDynamic({ layout, content, apidata }) {
  const chart_data = useMemo(() => {
    if (content) {
      const table_id = content?.tbl_id;
      const api_data = apidata?.allTablesData;
      let filterd_data = api_data?.filter((items) => items?.[table_id]);
      return filterd_data?.[0]?.[table_id];
    }
  }, [apidata, layout?.sctn_id]);

  const allColumnLength = layout?.pageContent?.[0]?.tableFieldData?.length;

  return (
    <>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ backgroundColor: 'transparent' }}
        style={{ maxHeight: 400 }}
      >
        <Table aria-label="simple table" size="small" sx={{ border: '1px solid #75a1c7' }}>
          <TableHead sx={{ backgroundColor: '#e3f5ff' }}>
            <TableRow>
              <TableCell
                align="center"
                colSpan={allColumnLength}
                sx={{
                  borderBottom: '2px solid #75a1c7',
                  color: '#00007f',
                  fontSize: '18px',
                  fontWeight: 'bold'
                }}
              >
                {layout?.lbl}
              </TableCell>
            </TableRow>

            <TableRow id="sulatara" sx={{ height: '4rem', borderBottom: '1.8px solid #75a1c7' }}>
              {layout?.pageContent?.[0]?.tableFieldData?.map((item) => (
                <>
                  <TableCell align="start" sx={{ fontWeight: 'bold', color: '#5b87d9' }}>
                    {item?.fld_l}
                  </TableCell>
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {chart_data?.map((item) => (
              <>
                <TableRow
                  key={item.party_id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {layout?.pageContent?.[0]?.tableData.map((child) => (
                    <>
                      <TableCell align="start" sx={{ fontWeight: 'bold' }}>
                        {item[child?.fld_id]}
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

BasicTableDynamic.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object
};
